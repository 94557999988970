import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { ProductWithSides, Session } from 'shared-types';

import API from 'services/API';

import useLayoutStore from './stores/useLayoutStore';

type Params = {
  products: ProductWithSides[];
  totalProductsCount: number;
  session?: Session;
  sessionId: string;
  onWarning?: () => void;
};

const useConfirmProducts = ({
  products,
  totalProductsCount,
  session,
  sessionId,
  onWarning,
}: Params) => {
  const { t } = useTranslation();

  const setShouldHideConfirmation = useLayoutStore((state) => state.setShouldHideConfirmation);

  const [isConfirming, setIsConfirming] = useState(false);
  const shouldStopConfirmation = useRef(false);

  const quantity = session?.quantity;
  const sidesCount = session?.sidesCount ?? 0;
  const minimalDpi = session?.options?.minimalDpi;

  const missingProductMsg = t('ConfirmValidation.MissingProduct') || 'Error: Missing product.';
  // const getMissingProductMessage = (missingNumber: number) =>
  //   `Please upload ${missingNumber} more pages`;
  const missingAssetMsg = t('ConfirmValidation.MissingAsset') || 'Error: Missing asset.';

  // const missingSidesMsg = t('ConfirmValidation.MissingSides') || 'Error: Missing sides.';
  const getMissingSidesMessage = (missingNumber: number) => {
    const isNegative = missingNumber < 0;
    if (isNegative) return `Please remove ${Math.abs(missingNumber)} page or side`;
    return `Please upload ${missingNumber} more pages or sides`;
  };

  const setShouldStopConfirmation = useCallback((value: boolean) => {
    shouldStopConfirmation.current = value;
  }, []);

  const validateConfirmation = useCallback(() => {
    const hasNoProducts = products.length === 0;

    // Validate the product count.
    const missingProduct = (quantity ?? 0) > totalProductsCount;
    console.log('missingProduct', missingProduct, totalProductsCount, quantity);

    if (hasNoProducts || missingProduct) throw new Error(missingProductMsg);

    // Validate the asset count.
    const hasNoAssets = products.every((product) => product.sides.every((side) => !side.assetId));
    const missingAsset = products.some((product) => product.sides.some((side) => !side.assetId));
    if (hasNoAssets || missingAsset) throw new Error(missingAssetMsg);

    // Validate the sides count.
    const missingSides = products.some((product) => product.sides.length !== sidesCount);
    const missingSidesMessage = getMissingSidesMessage(sidesCount - products[0].sides.length);

    if (missingSides) throw new Error(missingSidesMessage);

    // Validate the DPI.
    const lowDpi = products.some((product) =>
      product.sides.some(
        (side) => (side.resizeMetadata?.lowestResolution ?? 0) > (minimalDpi ?? 0),
      ),
    );

    // Show the warning modal if the DPI is too low and proceed confirmation is not set or false
    if (lowDpi && !shouldStopConfirmation.current) {
      onWarning?.();
    }
  }, []);

  const handleConfirm = useCallback(async () => {
    try {
      validateConfirmation();
      setIsConfirming(true);

      console.log('Clicked', shouldStopConfirmation.current);
      if (shouldStopConfirmation.current) return;
      console.log('shouldProceedConfirmation.current', shouldStopConfirmation.current);

      const {
        meta: { message = '', status },
      } = await API.confirmSession(sessionId, { confirmed: true });

      if (status !== 200) {
        throw new Error(t('ConfirmValidation.ApiError') || message);
      }

      setShouldHideConfirmation(true);

      notification.open({
        message: t('SuccessConfirmation.Message'),
        description: t('SuccessConfirmation.Desc'),
        duration: 5,
        type: 'success',
      });
    } catch (error: any) {
      notification.open({
        message: t('Error'),
        description: error?.message,
        duration: 5,
        type: 'error',
      });
    } finally {
      setIsConfirming(false);
      setShouldStopConfirmation(false);
    }
  }, []);

  return {
    handleConfirm,
    isConfirming,
    setShouldStopConfirmation,
  };
};

export default useConfirmProducts;
