import Modal from 'react-modal';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { Side } from 'shared-types';

import Box from 'components/Box/Box';
import SContainer from 'components/Container/Container';
import FilePreview from 'components/ProductSide/FilePreview';
import ToolsBar from 'components/Tools/ToolsBar';

import { imagePlaces, scalingMethods } from 'utils/constants';

Modal.setAppElement('#root');

const customStyles = {
  content: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: 0,
    border: 'none',
  },
};

type Props = {
  originalPdfFile: Blob;
  pdfFile: Blob | undefined;
  width: number;
  height: number;
  actions: Side['actions'];
  isOpen: boolean;
  onFlip: () => void;
  onRotate: (direction: 'left' | 'right') => void;
  onChangePlace: (place: string) => void;
  onChangeType: (type: string) => void;
  onClose: () => void;
};

function Editor({
  pdfFile,
  originalPdfFile,
  actions,
  onFlip,
  onRotate,
  onChangePlace,
  onChangeType,
  isOpen,
  onClose,
}: Props) {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={customStyles}
        contentLabel="File Editor"
      >
        <SContainer>
          <Box flexGrow={1} display="flex" justifyContent="center" alignItems="center">
            <FilePreview
              file={pdfFile || originalPdfFile}
              isFullPreview={true}
              loading={false}
              pageWidth={200}
            />
          </Box>
          <Box textAlign="left" p={3} pb={9} width={300} minWidth={300} background="#ffffff">
            <Box textAlign="right">
              <CloseOutlined style={{ cursor: 'pointer' }} onClick={onClose} />
            </Box>
            <ToolsBar
              place={actions.place}
              type={actions.type}
              imagePlaces={imagePlaces}
              scalingMethods={scalingMethods}
              onChangePlace={onChangePlace}
              onRotateRight={() => onRotate('right')}
              onRotateLeft={() => onRotate('left')}
              onFlip={onFlip}
              onChangeType={onChangeType}
            />
          </Box>
        </SContainer>
      </Modal>
    </>
  );
}

export default Editor;
