import { Session } from 'shared-types';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import useLayoutStore from './useLayoutStore';

type SessionState = {
  sessionId: string;
  session: Session | undefined;
};

type SessionActions = {
  setSessionId: (sessionId: string) => void;
  setSession: (config: Session) => void;
};

const useSessionStore = create(
  subscribeWithSelector<SessionState & SessionActions>((set) => ({
    sessionId: '',
    session: undefined,
    setSessionId: (sessionId: string) => set({ sessionId }),
    setSession: (session: Session) => set({ session }),
  })),
);

// when session is confirmed, we should hide the confirmation button
useSessionStore.subscribe((state) => {
  if (state.session?.confirmation.confirmed) {
    useLayoutStore.getState().setShouldHideConfirmation(true);
  }
});

export default useSessionStore;
