import { degrees, PDFDocument, rgb } from 'pdf-lib';

import { IMAGE_PlACE, SCALING_METHOD } from 'utils/constants';
import { getFileName } from 'utils/misc';

export const getSizeInPixel = (sizeInMM) => {
  return (sizeInMM / 25.4) * 72;
};

const mod = (n, m) => {
  return ((n % m) + m) % m;
};

const BLEED = getSizeInPixel(100);
const ONE_CM = getSizeInPixel(10);

const normalizeBleedConfig = (config = {}) => {
  let left, right, top, bottom;
  if (Object.keys(config) < 1) {
    throw new Error('Invalid bleed config', config);
  }
  if (config.all) {
    left = right = top = bottom = config.all;
  } else {
    left = config.left;
    right = config.right;
    top = config.top;
    bottom = config.bottom;
  }
  return {
    left: getSizeInPixel(left),
    right: getSizeInPixel(right),
    top: getSizeInPixel(top),
    bottom: getSizeInPixel(bottom),
  };
};

// const rotateTrimbox = ({ trimbox, rotation }) => {
//   if (rotation === 90 || rotation === 180) {
//     const { x, y, width, height } = trimbox;

//     trimbox.x = y;
//     trimbox.y = x;
//     trimbox.width = height;
//     trimbox.height = width;
//   }
//   return trimbox;
// };
const rotateTrimbox = ({ trimbox, page, rotation }) => {
  console.log('rotateTrimbox', trimbox, page);
  const { x, y, width, height } = trimbox;
  const pageWidth = page.width;
  const pageHeight = page.height;

  switch (rotation) {
    case 90:
      return {
        x: y,
        y: x,
        width: height,
        height: width,
      };
    case 180:
      return {
        x: pageWidth - width - x,
        y: pageHeight - height - y,
        width: width,
        height: height,
      };
    case 270:
      return {
        x: pageHeight - height - y,
        y: pageWidth - width - x,
        width: height,
        height: width,
      };
    default:
      return trimbox;
  }
};

/**
 * Creates a PDF file with the given options.
 *
 * @param {string} pdfFile - The PDF file to be processed.
 * @param {object} options - The options for creating the PDF.
 * @param {string} options.type - The scaling method for the PDF.
 * @param {string} options.place - The placement method for the PDF.
 * @param {number} options.rotation - The rotation angle for the PDF.
 * @param {object} options.dimensions - The dimensions of the PDF.
 * @param {object} options.productSize - The size of the product.
 * @param {boolean} [showBleed=true] - Whether to show the bleed area in the PDF.
 * @returns {Promise<string>} The processed PDF file.
 */
const createPDF = async (pdfFile, options, showBleed = true) => {
  console.log('createPDF');
  const { type, place, rotation, dimensions, productSize, cropArea, assetMetaData } = options;
  let file;
  let doRotate = false;
  if (!assetMetaData.rotation || assetMetaData.rotation !== rotation) {
    doRotate = true;
  }

  file = await rotate(pdfFile, {
    dimensions,
    rotation: rotation, // do not rotate again
  });

  let bleed = options.bleed;
  bleed = normalizeBleedConfig(bleed);

  console.log('assetMetaData before', assetMetaData);

  if (doRotate) {
    assetMetaData.trimbox = rotateTrimbox({ ...assetMetaData, rotation });
    if (rotation === 90 || rotation === 270) {
      const w = assetMetaData.page.width;
      const h = assetMetaData.page.height;
      assetMetaData.page.height = w;
      assetMetaData.page.width = h;
    }
    assetMetaData.rotation = rotation;
  }
  console.log('assetMetaData after', assetMetaData);
  console.log('trimbox after rotation', assetMetaData.trimbox);
  console.log('WH 1', getSizeInPixel(productSize.width), getSizeInPixel(productSize.height));

  const width = getSizeInPixel(productSize.width) + bleed.right + bleed.left;
  const height = getSizeInPixel(productSize.height) + bleed.top + bleed.bottom;

  const optionsParams = {
    rotation: 0, // do not rotate again, trimbox and file are already rotated
    place,
    type,
    width,
    height,
    bleed,
    cropArea,
    assetMetaData,
  };
  switch (type) {
    case SCALING_METHOD.CROP:
      file = await createCropPDF(file, optionsParams);
      break;
    case SCALING_METHOD.STRETCH:
      file = await createStretchPDF(file, optionsParams);
      break;
    default:
      file = await createFitPDF(file, optionsParams);
  }

  if (showBleed) {
    file = await drawBleed(file, { width, height, bleed: bleed });
  } else {
    //file = await drawTrimBox(file, { width, height, bleed });
    // file = await drawCutMarks(file, { width, height, bleed });
  }
  if (options.flip) {
    file = await flipPDF(file);
  }
  return file;
};

const getScale = (srcPage, place, { width, height, bleed, trimbox }, min = true) => {
  const fn = min ? 'min' : 'max';

  const trimboxScaleX = (width - bleed.left - bleed.right) / trimbox.width;
  const trimboxScaleY = (height - bleed.top - bleed.bottom) / trimbox.height;

  switch (place) {
    case IMAGE_PlACE.NET:
      return Math[fn]((width - BLEED) / srcPage.getWidth(), (height - BLEED) / srcPage.getHeight());
    case IMAGE_PlACE.NEGATIVE_WHITE_SPACE:
      return Math[fn](
        (width - BLEED + ONE_CM) / srcPage.getWidth(),
        (height - BLEED + ONE_CM) / srcPage.getHeight(),
      );
    default:
      return Math[fn](trimboxScaleX, trimboxScaleY);
  }
};

const getBleed = (place) => {
  switch (place) {
    case IMAGE_PlACE.NET:
      return BLEED;
    case IMAGE_PlACE.NEGATIVE_WHITE_SPACE:
      return BLEED - ONE_CM;
    default:
      return 0;
  }
};

const finializePage = async (pdfDoc) => {
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

const generateBleedBox = ({ bleed, width, height } = {}) => {
  const { left, right, top, bottom } = bleed;
  return {
    x: left,
    y: bottom,
    width: width - right - left,
    height: height - top - bottom,
  };
};

export const drawBleed = async (pdfFile, { width, height, bleed }) => {
  const { left, right, top, bottom } = bleed;
  console.log('drawBleed', bleed);
  const sourcePdf = await PDFDocument.load(pdfFile);

  // get first source page
  const srcPage = sourcePdf.getPage(0);

  const config = generateBleedBox({ bleed, height, width });
  const opacity = 0.3;

  srcPage.setBleedBox(config.x, config.y, config.width, config.height);
  srcPage.drawLine({
    start: { x: 0, y: 0 },
    end: { x: width, y: 0 },
    thickness: bottom * 2,
    color: rgb(1, 0, 0),
    opacity,
  });
  srcPage.drawLine({
    start: { x: 0, y: height },
    end: { x: width, y: height },
    thickness: top * 2,
    color: rgb(1, 0, 0),
    opacity,
  });
  srcPage.drawLine({
    start: { x: 0, y: height - top },
    end: { x: 0, y: bottom },
    thickness: left * 2,
    color: rgb(1, 0, 0),
    opacity,
  });
  srcPage.drawLine({
    start: { x: width, y: height - top },
    end: { x: width, y: bottom },
    thickness: right * 2,
    color: rgb(1, 0, 0),
    opacity,
  });

  return finializePage(sourcePdf);
};

// const drawTrimBox = async (pdfFile, { width, height, bleed }) => {
//   const sourcePdf = await PDFDocument.load(pdfFile);

//   // get first source page
//   const srcPage = sourcePdf.getPage(0);

//   const config = generateBleedBox({ bleed, height, width });

//   srcPage.setTrimBox(config.x, config.y, config.width, config.height);

//   return finializePage(sourcePdf);
// };

// const drawCutmarkLine = (page, { start, end } = {}) => {
//   const outerThickness = getSizeInPixel(2);
//   const innerThickness = getSizeInPixel(1);

//   page.drawLine({
//     start,
//     end,
//     thickness: outerThickness,
//     color: rgb(1, 1, 1),
//     opacity: 1,
//   });

//   page.drawLine({
//     start,
//     end,
//     thickness: innerThickness,
//     color: rgb(0, 0, 0),
//     opacity: 1,
//   });
// };

// const drawCutMarks = async (pdfFile, { width, height, bleed }) => {
//   const margin = getSizeInPixel(3);
//   const { right, left, top, bottom } = bleed;

//   // load pdf file
//   const sourcePdf = await PDFDocument.load(pdfFile);

//   // get first source page
//   const srcPage = sourcePdf.getPage(0);

//   const topRightH = {
//     start: { x: width - right + margin, y: height - top },
//     end: { x: width - margin, y: height - top },
//   };
//   const topRightV = {
//     start: { x: width - right, y: height - top + margin },
//     end: { x: width - right, y: height - margin },
//   };
//   const bottomRightH = {
//     start: { x: width - right + margin, y: bottom },
//     end: { x: width - margin, y: bottom },
//   };
//   const bottomRightV = {
//     start: { x: width - right, y: bottom - margin },
//     end: { x: width - right, y: margin },
//   };
//   const bottomLeftH = {
//     start: { x: margin, y: bottom },
//     end: { x: left - margin, y: bottom },
//   };
//   const bottomLeftV = {
//     start: { x: left, y: bottom - margin },
//     end: { x: left, y: margin },
//   };
//   const topLeftH = {
//     start: { x: margin, y: height - top },
//     end: { x: left - margin, y: height - top },
//   };
//   const topLeftV = {
//     start: { x: left, y: height - top + margin },
//     end: { x: left, y: height - margin },
//   };
//   const cuts = [
//     topRightH,
//     topRightV,
//     bottomRightH,
//     bottomRightV,
//     bottomLeftH,
//     bottomLeftV,
//     topLeftH,
//     topLeftV,
//   ];

//   cuts.forEach((conifg) => {
//     drawCutmarkLine(srcPage, conifg);
//   });

//   return finializePage(sourcePdf);
// };

const createEmptyPage = (pdfDoc, { width, height }) => {
  const page = pdfDoc.addPage([width, height]);
  return page;
};

export const rotate = async (pdfFile, { rotation, dimensions } = {}) => {
  const sourcePdf = await PDFDocument.load(pdfFile);

  // get first source page
  const srcPage = sourcePdf.getPage(0);
  const pageAngle = srcPage.getRotation().angle;
  let degree = mod(-1 * (rotation + pageAngle), 360);

  const { width, height } = dimensions;

  let newHeight;
  let newWidth;
  if (degree === 90 || degree === 270) {
    newHeight = width;
    newWidth = height;
  } else {
    newHeight = height;
    newWidth = width;
  }

  const page = sourcePdf.addPage([newWidth, newHeight]);
  const embeddedPage = await sourcePdf.embedPage(srcPage);

  const dimensionsVsDegree = {
    0: [0, 0, newWidth, newHeight],
    90: [newWidth, 0, newHeight, newWidth],
    180: [width, height, newWidth, newHeight],
    270: [0, newHeight, newHeight, newWidth],
  };

  const [x, y, pageWidth, pageHeight] = dimensionsVsDegree[degree];
  page.drawPage(embeddedPage, {
    x,
    y,
    width: pageWidth,
    height: pageHeight,
    rotate: degrees(degree),
  });

  sourcePdf.removePage(0);
  return finializePage(sourcePdf);
};

// const fitDirectionShift = (assetTrimbox, productTrimbox) => {
//   if (assetTrimbox.width - productTrimbox.width > assetTrimbox.height - productTrimbox.height) {
//     const scale = productTrimbox.height / assetTrimbox.height;
//     const aW = assetTrimbox.width * scale;

//     // we need to shift half of the difference between the scaled asset trimbox height and the product trimbox height
//     const diff = (aW - productTrimbox.width) / 2;

//     return { x: (diff / aW) * 100, y: 0 };
//   }
//   if (assetTrimbox.width - productTrimbox.width < assetTrimbox.height - productTrimbox.height) {
//     var scale = productTrimbox.width / assetTrimbox.width;
//     var aH = assetTrimbox.height * scale;

//     // we need to shift half of the difference between the scaled asset trimbox height and the product trimbox height
//     const diff = (aH - productTrimbox.height) / 2;

//     return { x: 0, y: (diff / aH) * 100 };
//   }

//   if (assetTrimbox.width - productTrimbox.width === assetTrimbox.height - productTrimbox.height) {
//     return { x: 0, y: 0 };
//   }
// };

export const createCropArea = (assetTrimbox, productTrimbox) => {
  let cX, cY;

  const assetWidthHeightFactor = assetTrimbox.width / assetTrimbox.height;
  const productWidthHeightFactor = productTrimbox.width / productTrimbox.height;

  if (assetWidthHeightFactor > productWidthHeightFactor) {
    const scale = productTrimbox.height / assetTrimbox.height;
    const aW = assetTrimbox.width * scale;

    // we need to shift half of the difference between the scaled asset trimbox height and the product trimbox height
    const diff = (aW - productTrimbox.width) / 2;
    cX = (diff / aW) * 100;
    cY = 0;
    return { x: cX, y: cY, width: 100 - 2 * cX, height: 100 };
  } else if (assetWidthHeightFactor < productWidthHeightFactor) {
    const scale = productTrimbox.width / assetTrimbox.width;
    const aH = assetTrimbox.height * scale;

    // we need to shift half of the difference between the scaled asset trimbox height and the product trimbox height
    const diff = (aH - productTrimbox.height) / 2;

    cX = 0;
    cY = (diff / aH) * 100;
    return { x: 0, y: cY, width: 100, height: 100 - 2 * cY };
  } else {
    return { x: 0, y: 0, width: 100, height: 100 };
  }
};

const createCropAreaInclBleed = (assetTrimbox, productSize, productBleed) => {
  console.log('createCropAreaInclBleed');
  // Input validatie
  if (
    !assetTrimbox?.width ||
    !assetTrimbox?.height ||
    !productSize?.width ||
    !productSize?.height ||
    !productBleed?.left ||
    !productBleed?.right ||
    !productBleed?.top ||
    !productBleed?.bottom
  ) {
    console.error('Invalid input parameters:', { assetTrimbox, productSize, productBleed });
    return { x: 0, y: 0, width: 100, height: 100 };
  }

  // Bereken totale product dimensies inclusief bleed
  const totalProductWidth = productSize.width + productBleed.left + productBleed.right;
  const totalProductHeight = productSize.height + productBleed.top + productBleed.bottom;

  const assetRatio = assetTrimbox.width / assetTrimbox.height;
  const productRatio = totalProductWidth / totalProductHeight;

  let cropX = 0;
  let cropY = 0;
  let cropWidth = 100;
  let cropHeight = 100;

  if (assetRatio > productRatio) {
    // Asset is breder - hoogte is leidend
    const scale = totalProductHeight / assetTrimbox.height;
    const scaledWidth = assetTrimbox.width * scale;
    const excess = scaledWidth - totalProductWidth;
    cropX = (excess / (2 * scaledWidth)) * 100;
    cropWidth = 100 - 2 * cropX;
  } else if (assetRatio < productRatio) {
    // Asset is hoger - breedte is leidend
    const scale = totalProductWidth / assetTrimbox.width;
    const scaledHeight = assetTrimbox.height * scale;
    const excess = scaledHeight - totalProductHeight;
    cropY = (excess / (2 * scaledHeight)) * 100;
    cropHeight = 100 - 2 * cropY;
  }

  console.log('Crop area calculation:', {
    input: {
      assetTrimbox,
      productSize,
      productBleed,
      assetRatio,
      productRatio,
      totalProductWidth,
      totalProductHeight,
    },
    output: {
      x: cropX,
      y: cropY,
      width: cropWidth,
      height: cropHeight,
    },
  });

  return {
    x: cropX,
    y: cropY,
    width: cropWidth,
    height: cropHeight,
  };
};

export const flipPDF = async (pdfFile) => {
  const sourcePdf = await PDFDocument.load(pdfFile);

  // get first source page
  const srcPage = sourcePdf.getPage(0);

  srcPage.scale(-1, 1);

  return finializePage(sourcePdf);
};

export const flipPreviewPDF = async (rawFile) => {
  if (!rawFile) {
    return;
  }
  const pdfFile = await rawFile.arrayBuffer();
  const generatedFile = await flipPDF(pdfFile);
  return new Blob([generatedFile]);
};

export const createFitPDF = async (pdfFile, { place, width, height, bleed, rotation }) => {
  // Create a new PDFDocument
  const pdfDoc = await PDFDocument.create();

  // Convert to array bytes
  const sourcePdf = await PDFDocument.load(pdfFile);

  // get first source page
  const srcPage = sourcePdf.getPage(0);

  // Embed the source pdf into the target pdf
  const embeddedPage = await pdfDoc.embedPage(srcPage);
  const scale = getScale(srcPage, place, { width, height, bleed });

  const embeddedPageDims = embeddedPage.scale(scale);

  const page = createEmptyPage(pdfDoc, { width, height, rotation });

  page.drawPage(embeddedPage, {
    ...embeddedPageDims,
    x: width / 2 - embeddedPageDims.width / 2,
    y: height / 2 - embeddedPageDims.height / 2,
  });

  return finializePage(pdfDoc);
};

/**
 * Creates a cropped PDF document based on the provided parameters.
 *
 * @param {string} pdfFile - The path or URL of the source PDF file.
 * @param {object} options - The options for cropping the PDF.
 * @param {string} options.place - The placement of the cropped area.
 * @param {number} options.width - The width of the cropped area.
 * @param {number} options.height - The height of the cropped area.
 * @param {number} options.bleed - The bleed of the cropped area.
 * @param {number} options.rotation - The rotation of the cropped area.
 * @returns {Promise<PDFDocument>} The cropped PDF document.
 */
export const createCropPDF = async (pdfFile, { width, height, bleed, cropArea, assetMetaData }) => {
  const rotation = 0;
  console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX CROP XXXXXXXXXXXXXXXXXXXXXXXX');
  // Create a new PDFDocument
  const pdfDoc = await PDFDocument.create();

  // Convert to array bytes
  const sourcePdf = await PDFDocument.load(pdfFile);

  // get first source page
  const srcPage = sourcePdf.getPage(0);

  // Read the trimboxes of asset and product.
  // - create a new trimbox for asset if not available on page size
  // - calculate the trimbox for the product based on the bleed settings
  if (!assetMetaData) assetMetaData = {};
  const productTrimbox = {
    x: bleed.left,
    y: bleed.bottom,
    width: width - bleed.left - bleed.right,
    height: height - bleed.bottom - bleed.top,
  };
  const assetTrimbox = assetMetaData.trimbox;
  const assetSize = assetMetaData.page;

  const assetTopY = assetSize.height - assetTrimbox.y - assetTrimbox.height;
  const productTopY = height - productTrimbox.y - productTrimbox.height;

  console.log('assetSize', assetSize, assetTopY);
  //console.log('fitDirectionShift', fitDirectionShift(assetTrimbox, productTrimbox));

  console.log('width: ', width);
  console.log('height: ', height);
  console.log('assetTrimbox', assetTrimbox);
  console.log('productTrimbox', productTrimbox, productTopY);
  console.log('rotation', rotation);

  // create crop area if not set. Place the asset centered in the trimbox of the product
  //const shift = fitDirectionShift(assetTrimbox, productTrimbox);
  if (!cropArea) {
    cropArea = createCropArea(assetTrimbox, productTrimbox);
    // cropArea = createCropArea({
    //   x: shift.x,
    //   y: shift.y,
    //   productTrimbox,
    //   bleed,
    //   assetTrimbox,
    //   assetWidth: srcPage.getWidth(),
    //   assetHeight: srcPage.getHeight(),
    // });
  }
  console.log('cropArea1', cropArea);

  // calculate crop area from percentage to pixels, compare to product trimbox
  const cropWidthPx = (productTrimbox.width / cropArea.width) * 100;
  const cropHeightPx = (productTrimbox.height / cropArea.height) * 100;
  const cropXPx = (cropWidthPx * cropArea.x) / 100;
  const cropYPx = cropHeightPx - productTrimbox.height - (cropHeightPx * cropArea.y) / 100;

  console.log('cropWidthPx', cropWidthPx);
  console.log('cropHeightPx', cropHeightPx);
  console.log('cropXPx', cropXPx);
  console.log('cropYPx', cropYPx);

  // calculate scale from crop pixels compared to asset trimbox
  const scaleX = (cropWidthPx / productTrimbox.width) * (productTrimbox.width / assetTrimbox.width);
  const scaleY =
    (cropHeightPx / productTrimbox.height) * (productTrimbox.height / assetTrimbox.height);

  let scale;
  scale = scaleX > scaleY ? scaleX : scaleY;

  console.log('scale', scaleX, scaleY, scale);

  // calculate size of asset left and bottom bleed
  const assetLeftBleedPx = assetTrimbox.x * scale;
  const assetBottomBleedPx = assetTrimbox.y * scale;

  const assetTopBleedPx = assetTopY * scale;

  console.log('leftBleedPx', assetLeftBleedPx);
  console.log('bottomBleedPx', assetBottomBleedPx);
  console.log('topBleedPx', assetTopBleedPx);

  // calculate distance from the left bottom corner of the asset to the left bottom corner of the product trimbox
  // const x = productTrimbox.x - cropXPx - assetLeftBleedPx;
  // const y = productTrimbox.y - cropYPx - assetBottomBleedPx;
  const x = productTrimbox.x - cropXPx - assetLeftBleedPx + (assetMetaData.trimbox.offsetX || 0);
  const y = productTrimbox.y - cropYPx - assetBottomBleedPx + (assetMetaData.trimbox.offsetY || 0);

  // we moeten de ruimte boven de trimbox weten.
  // const y = productTopY - (assetTopY * scale) - cropYPx;

  console.log('x', x);
  console.log('y', y);

  // flip y because of different coordinate system of crop editor and pdf-lib
  const yBottom = height - y - srcPage.getHeight() * scale;

  console.log('yBottom', yBottom);
  console.log('src height', srcPage.getHeight(), srcPage.getHeight() * scale, height);

  // set source page scale in pdf-lib
  srcPage.scale(scale, scale);

  // create an empty page with the given size
  const page = createEmptyPage(pdfDoc, { width, height, rotation });

  // insert the scaled source page into the output PDF page
  let embeddedPage;
  embeddedPage = await pdfDoc.embedPage(srcPage);

  // let pdf-lib draw the inserted page on the correct position
  page.drawPage(embeddedPage, {
    x, //-x * scaleX, // Adjust x position to scale and align correctly
    y: y, // * scaleY, // Adjust y position to scale and align correctly
    width: srcPage.getWidth(), //page.getWidth() * scaleX,
    height: srcPage.getHeight(), //page.getHeight() * scaleY,
  });

  return finializePage(pdfDoc);
};

export const createCropPDFWithSpecificSize = async (
  pdfFile,
  { place, width, height, bleed, rotation, cropArea },
) => {
  const {
    x: percentageX,
    y: percentageY,
    width: percentageWidth,
    height: percentageHeight,
  } = cropArea;

  // Create a new PDFDocument
  const pdfDoc = await PDFDocument.create();

  // Convert to array bytes
  const sourcePdf = await PDFDocument.load(pdfFile);

  // get first source page
  const srcPage = sourcePdf.getPage(0);
  // scale the source page, so that the cropped area is the same as the original area
  const pageWidth = (width / percentageWidth) * 100;
  const pageHeight = (height / percentageHeight) * 100;

  const page = pdfDoc.addPage([pageWidth, pageHeight]);

  // convert from percentage to pixel
  const x = (percentageX / 100) * page.getWidth();
  const y = (percentageY / 100) * page.getHeight();
  const croppedWidth = (percentageWidth / 100) * page.getWidth();
  const croppedHeight = (percentageHeight / 100) * page.getHeight();

  // get y value from bottom instead of top
  const yBottom = page.getHeight() - y - croppedHeight;

  const embeddedPage = await pdfDoc.embedPage(srcPage);

  page.setCropBox(x, yBottom, croppedWidth, croppedHeight);

  page.drawPage(embeddedPage, {
    x: 0,
    y: 0,
    width: page.getWidth(),
    height: page.getHeight(),
  });

  // Finalize the PDF and return it
  return await pdfDoc.save();
};

export const createStretchPDF = async (pdfFile, { place, width, height, rotation }) => {
  // Create a new PDFDocument
  const pdfDoc = await PDFDocument.create();

  // Convert to array bytes
  const sourcePdf = await PDFDocument.load(pdfFile);

  // get first source page
  const srcPage = sourcePdf.getPage(0);

  // Embed the source pdf into the target pdf
  const embeddedPage = await pdfDoc.embedPage(srcPage);

  const page = createEmptyPage(pdfDoc, { width, height, rotation });

  const bleed = getBleed(place);

  page.drawPage(embeddedPage, {
    width: page.getWidth() - bleed,
    height: page.getHeight() - bleed,
    x: bleed / 2,
    y: bleed / 2,
  });

  return finializePage(pdfDoc);
};

/**
 * Creates a preview PDF file from a raw file.
 * @param {Blob} rawFile - The raw file to create the preview PDF from.
 * @param {object} options - The options for creating the preview PDF.
 * @returns {Promise<Blob>} A promise that resolves to the generated preview PDF file as a Blob.
 */
export const createPreviewPdf = async (rawFile, options) => {
  if (!rawFile) {
    return;
  }
  const pdfFile = await rawFile.arrayBuffer();
  const generatedFile = await createPDF(pdfFile, options, true);
  return new Blob([generatedFile]);
};

export const createProductionPdf = async (pdfFile, options) => {
  if (!pdfFile) {
    return;
  }
  const generatedFile = await createPDF(pdfFile, options, false);
  return generatedFile;
};

export const convertImageToPDF = async (file, { width, height } = {}) => {
  const imageBytes = typeof file === 'string' ? file : await file.arrayBuffer();

  const pdfDoc = await PDFDocument.create();

  const jpgImage = await pdfDoc.embedJpg(imageBytes);

  const page = pdfDoc.addPage([width, height]);

  page.drawImage(jpgImage);

  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

export const convertImageToPDFAndGenerateFile = async (file, { width, height, filename } = {}) => {
  const pdfBytes = await convertImageToPDF(file, { width, height });

  return new File([pdfBytes], `${getFileName(filename)}.pdf`, {
    type: 'application/pdf',
  });
};

export const resize = async (pdfFile) => {
  const sourcePdf = await PDFDocument.load(pdfFile);

  // get first source page
  const srcPage = sourcePdf.getPage(0);

  const ratio = 100 / srcPage.getWidth();

  srcPage.scale(ratio, ratio);

  const pdfBytes = await sourcePdf.save();

  return new File([pdfBytes], pdfFile.name);
};

export const getPageCount = async (pdfFile) => {
  const sourcePdf = await PDFDocument.load(pdfFile);
  const pageCount = await sourcePdf.getPageCount();
  return pageCount;
};

export const extractPages = async (pdfFile, pages) => {
  const sourcePdf = await PDFDocument.load(pdfFile);

  // Create a new PDFDocument
  const pdfDoc = await PDFDocument.create();

  return Promise.all(
    pages.map(async (pageNum) => {
      const srcPage = sourcePdf.getPage(pageNum);
      const width = srcPage.getWidth();
      const height = srcPage.getHeight();

      // Embed the source pdf into the target pdf
      const embeddedPage = await pdfDoc.embedPage(srcPage);

      const page = createEmptyPage(pdfDoc, {
        width: srcPage.getWidth(),
        height: srcPage.getHeight(),
      });

      let degree = mod(-1 * (0 + srcPage.getRotation().angle), 360);

      const dimensionsVsDegree = {
        0: [0, 0, width, height],
        90: [width, 0, height, width],
        180: [width, height, width, height],
        270: [0, height, height, width],
      };

      const [x, y, pageWidth, pageHeight] = dimensionsVsDegree[degree];
      page.drawPage(embeddedPage, {
        x,
        y,
        width: pageWidth,
        height: pageHeight,
        rotate: degrees(degree),
      });
      const pdfBytes = await pdfDoc.save();

      return pdfBytes;
    }),
  );
};

export const combinePdfs = async (pdfFiles) => {
  if (!pdfFiles || !pdfFiles.length) {
    return;
  }
  const pdfDoc = await PDFDocument.create();
  for (const pdfFile of pdfFiles) {
    await pdfDoc.embedPdf(pdfFile);
    const [pdfPage] = await pdfDoc.embedPdf(pdfFile);
    const { width, height } = pdfPage.size();
    const page = pdfDoc.addPage([width, height]);
    page.drawPage(pdfPage);
  }
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

export const calculateCropToIncludeBleed = (assetTrimbox, productSize, bleed) => {
  // Calculate total product dimensions including bleed
  console.log('calculateCropToIncludeBleed', { assetTrimbox, productSize, bleed });

  const productWidth = getSizeInPixel(Number(productSize.width)); // + Number(bleed.left) + Number(bleed.right);
  const productHeight = getSizeInPixel(Number(productSize.height)); // + Number(bleed.top) + Number(bleed.bottom);

  const _bleed = {
    left: getSizeInPixel(Number(bleed.left)),
    top: getSizeInPixel(Number(bleed.top)),
    right: getSizeInPixel(Number(bleed.right)),
    bottom: getSizeInPixel(Number(bleed.bottom)),
  };

  const cropArea = createCropArea(assetTrimbox, {
    x: _bleed.left,
    y: _bleed.bottom,
    width: productWidth,
    height: productHeight,
  });
  console.log('Calculated crop result:', cropArea);

  // percentage upscale:
  const totalW = Number(productWidth) + Number(_bleed.left) + Number(_bleed.right);
  const totalH = Number(productHeight) + Number(_bleed.top) + Number(_bleed.bottom);
  console.log('total', { totalW, totalH, _bleed });

  let newY, newX, newWidth, newHeight;
  let scale;
  if (cropArea.y === 0) {
    newY = (_bleed.bottom / totalH) * 100;
    newHeight = (productHeight / totalH) * 100;
    scale = cropArea.height / newHeight;
    newX = cropArea.x + (_bleed.left / totalW) * 100;
    newWidth = cropArea.width * scale;
  } else {
    newX = (_bleed.left / totalW) * 100;
    newWidth = (productWidth / totalW) * 100;
    scale = cropArea.width / newWidth;
    newY = cropArea.y + (_bleed.bottom / totalH) * 100;
    newHeight = cropArea.height * scale;
  }
  console.log({ newX, newY, newWidth, newHeight, scale });

  const _result = {
    x: newX || 0,
    y: newY || 0,
    width: newWidth || 100,
    height: newHeight || 100,
  };
  console.log('_result', _result);
  return _result;
};

// const createCropArea = (assetTrimbox, productTrimbox) => {
//   let cX, cY;
//   console.log('w diff', assetTrimbox.width - productTrimbox.width);
//   console.log('h diff', assetTrimbox.height - productTrimbox.height);

//   const assetWidthHeightFactor = assetTrimbox.width / assetTrimbox.height;
//   const productWidthHeightFactor = productTrimbox.width / productTrimbox.height;
//   console.log('a diff', assetWidthHeightFactor);
//   console.log('p diff', productWidthHeightFactor);

//   //  if (assetTrimbox.width - productTrimbox.width > assetTrimbox.height - productTrimbox.height) {
//   if (assetWidthHeightFactor > productWidthHeightFactor) {
//     const scale = productTrimbox.height / assetTrimbox.height;
//     const aW = assetTrimbox.width * scale;

//     // we need to shift half of the difference between the scaled asset trimbox height and the product trimbox height
//     const diff = (aW - productTrimbox.width) / 2;
//     cX = (diff / aW) * 100;
//     cY = 0;
//     return { x: cX, y: cY, width: 100 - 2 * cX, height: 100 };
//   }
//   //  if (assetTrimbox.width - productTrimbox.width < assetTrimbox.height - productTrimbox.height) {
//   else if (assetWidthHeightFactor < productWidthHeightFactor) {
//     var scale = productTrimbox.width / assetTrimbox.width;
//     var aH = assetTrimbox.height * scale;

//     // we need to shift half of the difference between the scaled asset trimbox height and the product trimbox height
//     const diff = (aH - productTrimbox.height) / 2;

//     cX = 0;
//     cY = (diff / aH) * 100;
//     return { x: 0, y: cY, width: 100, height: 100 - 2 * cY };
//   }

//   //  if (assetTrimbox.width - productTrimbox.width == assetTrimbox.height - productTrimbox.height) {
//   //if (assetWidthHeightFactor == productWidthHeightFactor) {
//   else {
//     return { x: 0, y: 0, width: 100, height: 100 };
//   }
// };

export const createCropPDFv2 = async (pdfFile, placement, product, bleed) => {
  console.log('Starting createCropPDFv2 with:', {
    pdfFileType: pdfFile instanceof Blob ? 'Blob' : typeof pdfFile,
    pdfFileSize: pdfFile instanceof Blob ? pdfFile.size : 'N/A',
    placement,
    product,
    bleed,
  });

  try {
    // Log the blob details
    if (pdfFile instanceof Blob) {
      console.log('Blob details:', {
        size: pdfFile.size,
        type: pdfFile.type,
      });
    }

    // Convert Blob to ArrayBuffer and log its details
    const arrayBuffer = await pdfFile.arrayBuffer();
    console.log('ArrayBuffer created:', {
      byteLength: arrayBuffer.byteLength,
      isArrayBuffer: arrayBuffer instanceof ArrayBuffer,
    });

    // Create new PDFDocument
    const pdfDoc = await PDFDocument.create();
    console.log('New PDFDocument created');

    // Load source PDF and log details
    const sourcePdf = await PDFDocument.load(arrayBuffer);
    console.log('Source PDF loaded:', {
      pageCount: sourcePdf.getPageCount(),
    });

    // Get first page and log details
    const srcPage = sourcePdf.getPage(0);
    console.log('Source page details:', {
      width: srcPage.getWidth(),
      height: srcPage.getHeight(),
    });

    // Create new page
    const page = pdfDoc.addPage([product.width, product.height]);
    console.log('New page created with dimensions:', {
      width: product.width,
      height: product.height,
    });

    // Embed and draw page
    const [embeddedPage] = await pdfDoc.embedPdf(sourcePdf, [0]);
    console.log('Page embedded successfully');

    const xScale = placement.scale;
    const yScale = placement.scale;

    page.drawPage(embeddedPage, {
      x: placement.x * placement.scale - placement.shiftX,
      y: placement.y * placement.scale - placement.shiftY,
      xScale,
      yScale,
    });
    console.log('Page drawn with placement:', {
      x: placement.x * placement.scale,
      y: placement.y * placement.scale,
      xScale,
      yScale,
    });

    const finalizedPdf = await finializePage(pdfDoc);
    console.log('PDF finalized successfully');

    return finalizedPdf;
  } catch (err) {
    console.error('Error in createCropPDFv2:', {
      error: err,
      message: err.message,
      stack: err.stack,
    });
    throw err;
  }
};
