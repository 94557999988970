import { Asset } from 'shared-types';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

type AssetsState = {
  assets: Asset[];
};

type AssetsActions = {
  setAssets: (assets: Asset[]) => void;
  getAsset: (assetId: Asset['id']) => Asset | undefined;
};

const useAssetsStore = create(
  subscribeWithSelector<AssetsState & AssetsActions>((set, get) => ({
    assets: [],
    setAssets: (assets: Asset[]) => set({ assets }),
    getAsset: (assetId: Asset['id']) => {
      const { assets } = get();
      return assets.find((a) => a.id === assetId);
    },
  })),
);

export default useAssetsStore;
