import { useCallback, useEffect, useState } from 'react';
import { equals } from 'ramda';
import { RotationDirection, SideWithAsset } from 'shared-types';

const useActions = (initialActions: SideWithAsset['actions']) => {
  const [actions, setActions] = useState<SideWithAsset['actions']>(initialActions);

  useEffect(() => {
    // Alleen updaten als beide objects bestaan en verschillend zijn
    if (actions && initialActions && !equals(actions, initialActions)) {
      setActions(initialActions);
    }
  }, [actions, initialActions]);

  // Flip wordt lokaal afgehandeld omdat er geen floating point problemen zijn
  const handleFlip = useCallback(() => {
    setActions((prev) => ({
      ...prev,
      flip: !prev.flip,
    }));
  }, []);

  const changePlace = useCallback((place: SideWithAsset['actions']['place']) => {
    setActions((prev) => ({
      ...prev,
      place,
    }));
  }, []);

  const changeType = useCallback((type: SideWithAsset['actions']['type']) => {
    setActions((prev) => ({
      ...prev,
      type,
    }));
  }, []);

  // Reset actions wanneer initialActions veranderen
  useEffect(() => {
    setActions(initialActions);
  }, [initialActions]);

  return {
    newActions: actions,
    setActions, // Expose setActions voor directe updates
    handleFlip,
    changePlace,
    changeType,
  };
};

export default useActions;
