import { useQuery } from 'react-query';
import { Button, Col, Flex, Result, Row, Spin, Typography } from 'antd';
import { isNotNil } from 'ramda';
import { SideWithAsset } from 'shared-types';

import AssetsSidebar from 'components/AssetsSidebar/AssetsSidebar';
import Box from 'components/Box/Box';

import useProductsStore from 'hooks/stores/useProductsStore';
import useSessionStore from 'hooks/stores/useSessionStore';
import useQueryParams from 'hooks/useQueryParams';

import API from 'services/API';

import { SContainer, SLoaderContainer } from './Uploader.styles';

const MinimalBook = () => {
  const { sessionId } = useQueryParams();
  const { products, setProducts } = useProductsStore((state) => {
    return {
      products: state.products,
      selectedSideId: state.selectedSideId,
      setProducts: state.setProducts,
    };
  });

  const { setSessionId, setSession } = useSessionStore((state) => {
    return {
      setSessionId: state.setSessionId,
      setSession: state.setSession,
      session: state.session,
    };
  });

  const { isLoading: isLoadingProducts, isError: isProductsError } = useQuery(
    'products',
    () => API.getProducts(sessionId),
    {
      enabled: !!sessionId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        setProducts(data);
      },
    },
  );

  const { isLoading, isError: isSessionError } = useQuery(
    'session',
    () => API.getSession(sessionId),
    {
      enabled: !!sessionId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setSessionId(sessionId);
        setSession(data);
      },
    },
  );

  // Todo: Need to fix SideWithAsset[] type here.
  const sides = (products[0]?.sides as SideWithAsset[]) ?? [];

  if (isLoading || isLoadingProducts) {
    return (
      <SLoaderContainer>
        <Spin size="large" />
      </SLoaderContainer>
    );
  }

  if (isSessionError || isProductsError) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={<Button type="primary">Send an Email</Button>}
      />
    );
  }

  const isAssetUploaded = sides.length && isNotNil(sides[0]) && isNotNil(sides[0].asset);

  return (
    <SContainer>
      <Box>
        <Row gutter={[16, 16]}>
          <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 7 }}>
            <AssetsSidebar
              showInstructions={false}
              showAddBlankPage={false}
              shouldFetchAssets={false}
            >
              {isAssetUploaded ? (
                <Flex vertical justify="center" style={{ width: '100%', fontSize: 'bold' }}>
                  <Typography.Paragraph>Number of pages: {sides.length}</Typography.Paragraph>
                  <Typography.Paragraph>
                    Page Size: {sides[0].asset.metadata.page.widthMM} x{' '}
                    {sides[0].asset.metadata.page.heightMM}
                  </Typography.Paragraph>
                </Flex>
              ) : null}
            </AssetsSidebar>
          </Col>
        </Row>
      </Box>
    </SContainer>
  );
};
export default MinimalBook;
