import styled from 'styled-components';
import type {
  BackgroundProps,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  SpaceProps,
  TypographyProps,
} from 'styled-system';
import { background, color, flexbox, layout, space, typography } from 'styled-system';

type BoxProps = SpaceProps &
  LayoutProps &
  ColorProps &
  FlexboxProps &
  BackgroundProps &
  TypographyProps;

const Box = styled.div<BoxProps>`
  ${space}
  ${layout}
  ${color}
  ${flexbox}
  ${background}
  ${typography}
`;

export default Box;
