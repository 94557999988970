import { useTranslation } from 'react-i18next';
import { FcHighPriority } from 'react-icons/fc';
import { Divider, Flex, Typography } from 'antd';

const getMissingSidesMessage = (missingNumber: number, t: Function) => {
  const isNegative = missingNumber < 0;
  const noMissingSides = missingNumber === 0;
  if (isNegative) return t('RemovePageSide', { count: Math.abs(missingNumber) });
  if (noMissingSides) return null;
  return t('UploadMorePagesSides', { count: missingNumber });
};

export default function WarningMessages({
  actualSidesCount,
  sessionsSidesCount,
}: {
  actualSidesCount: number;
  sessionsSidesCount: number;
}) {
  const { t } = useTranslation();
  const missingOrAddSidesMessage = getMissingSidesMessage(sessionsSidesCount - actualSidesCount, t);

  if (!missingOrAddSidesMessage) return null;

  return (
    <div>
      <Divider>
        <Flex justify="space-between" align="center">
          <FcHighPriority style={{ fontSize: '1.5rem' }} />
          <Typography.Text>{t('Warning')}</Typography.Text>
        </Flex>
      </Divider>

      <Typography.Text>{missingOrAddSidesMessage}</Typography.Text>
    </div>
  );
}
