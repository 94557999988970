import { useQuery } from 'react-query';
import { Col } from 'antd';

import Box from 'components/Box/Box';
import ProductCard from 'components/ProductCard/ProductCard';

import useProductsStore from 'hooks/stores/useProductsStore';
import useSessionStore from 'hooks/stores/useSessionStore';

import API from 'services/API';

const getColsBySidesCount = (sidesCount: number) => {
  if (sidesCount === 1) {
    return 8;
  }
  if (sidesCount === 2) {
    return 12;
  }
  return 24;
};

function Products() {
  const sessionId = useSessionStore((state) => state.sessionId);
  const { products, setProducts } = useProductsStore((state) => {
    return {
      products: state.products,
      setProducts: state.setProducts,
    };
  });

  const { error, isLoading } = useQuery('products', () => API.getProducts(sessionId), {
    enabled: !!sessionId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onSuccess(data) {
      setProducts(data);
    },
  });

  const session = useSessionStore((state) => state.session);

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  if (error) {
    return null;
  }

  if (!products.length) {
    return <Box>No products</Box>;
  }

  return (
    <>
      {products.map((product, index) => {
        return (
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            md={{ span: getColsBySidesCount(session?.sidesCount || 0) }}
            key={index}
          >
            <ProductCard key={product.id} productIndex={index} product={product} />
          </Col>
        );
      })}
    </>
  );
}

export default Products;
