import { useTranslation } from 'react-i18next';
import { FcFile, FcGallery } from 'react-icons/fc';
import { Card, Divider, Flex, Typography } from 'antd';
import { Asset, SideWithAsset } from 'shared-types';

import useSessionStore from 'hooks/stores/useSessionStore';

import ResolutionMessage from './ResolutionMessage';
import WarningMessages from './WarningMessages';

type Props = {
  side: SideWithAsset;
  totalPageCount: number;
  sideNumber: number;
};

export default function ResultOutput({ side, totalPageCount, sideNumber }: Props) {
  const session = useSessionStore((state) => state.session);
  const { t } = useTranslation();

  if (!session) return null;

  const {
    sidesCount,
    productSize,
    options: { minimalDpi },
  } = session;
  const { resizeMetadata } = side;
  const { filename, pageNumber, metadata } = side.asset as Asset;
  const { scalingPercentage, lowestResolution } = resizeMetadata;

  return (
    <Card style={{ backgroundColor: '#f0f2f5' }}>
      {/* <DpiQualityIndicator dpi={50} /> */}
      <Divider>
        <Flex justify="space-between" align="center">
          <FcGallery style={{ fontSize: '1.5rem' }} />
          <Typography.Text>{t('ResultOutput.YourProduct')}</Typography.Text>
        </Flex>
      </Divider>
      <Flex vertical justify="flex-start" align="flex-start">
        <Typography.Text>
          {t('ResultOutput.PagesCount')}: {sidesCount}{' '}
        </Typography.Text>
        <Typography.Text>
          {t('ResultOutput.ProductSize')}: {productSize.width} x {productSize.height}{' '}
        </Typography.Text>
        <Typography.Text>
          {t('ResultOutput.MinimalResolution')}: {minimalDpi} DPI
        </Typography.Text>
      </Flex>

      <WarningMessages actualSidesCount={totalPageCount} sessionsSidesCount={sidesCount} />

      <Divider>
        <Flex justify="space-between" align="center">
          <FcFile style={{ fontSize: '1.5rem' }} />
          <Typography.Text strong>{t('ResultOutput.PageInfo')}</Typography.Text>
        </Flex>
      </Divider>

      <Flex vertical justify="flex-start" align="flex-start">
        <Typography.Text style={{ fontSize: '0.9rem' }}>
          {t('ResultOutput.PageNumber')}: {sideNumber}
        </Typography.Text>
        <Typography.Text style={{ fontSize: '0.9rem' }}>
          {t('ResultOutput.Scaling')}: {scalingPercentage}%
        </Typography.Text>
        <Typography.Text style={{ fontSize: '0.9rem' }}>
          {t('ResultOutput.FileFormat')}: PDF
        </Typography.Text>
        <Typography.Text style={{ fontSize: '0.9rem' }}>
          {t('ResultOutput.Font')}: {metadata.font?.name}
        </Typography.Text>
        <ResolutionMessage lowestResolution={lowestResolution} minimalDpi={minimalDpi} />
      </Flex>

      <Divider>
        <Flex justify="space-between" align="center">
          <Typography.Text strong>{t('ResultOutput.SourceFile')}</Typography.Text>
        </Flex>
      </Divider>

      <Flex vertical justify="flex-start" align="flex-start">
        <Typography.Text style={{ fontSize: '0.9rem' }}>
          {t('ResultOutput.Filename')}: {filename}
        </Typography.Text>
        <Typography.Text style={{ fontSize: '0.9rem' }}>
          {t('ResultOutput.PageNumber')}: {pageNumber}
        </Typography.Text>
        <Typography.Text style={{ fontSize: '0.9rem' }}>
          {t('ResultOutput.Size')}: {metadata.page?.widthMM} * {metadata.page?.heightMM} mm
        </Typography.Text>
      </Flex>
    </Card>
  );
}
