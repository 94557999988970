import styled from 'styled-components';

export const SContainer = styled.main`
  padding: 20px;
`;

export const SCard = styled.div`
  width: 480px;
  background: #ffffff;
  padding: 25px;
  border-radius: 8px;
  margin-bottom: 40px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const SLoaderContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const SUploadButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin: 0;
  color: #fff;
  background: #1fb264;
  border: none;
  padding: 10px;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 20px;
`;

export const SAssetPreview = styled.div`
  border: 1px solid #8d8d8d;
  border-radius: 8px;
  padding: 2px;
  margin-bottom: 8px;
  height: 130px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
  }
`;

export const SSticky = styled.div`
  position: sticky;
  top: 0;
  // z-index: 100;
  background: #fafafa;
  padding: 5px;
  padding-top: 2px;
`;
