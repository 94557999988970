import { isEmpty } from 'ramda';
import { Asset, ProductWithSides, Side, SideWithAsset } from 'shared-types';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import API from 'services/API';

import { getTotalProductsCount } from 'utils/misc';

import useAssetsStore from './useAssetsStore';
import useLayoutStore from './useLayoutStore';
import useSessionStore from './useSessionStore';

type WithAsset = SideWithAsset;
type Product = ProductWithSides | ProductWithSides<WithAsset>;

type ProductsState = {
  products: Product[] | [];
  totalProductsCount: number;
  selectedSideId?: string;
};
type UpdatePageNumberParams = {
  assetId: Asset['id'];
  productId: ProductWithSides['id'];
  pageNumber: Asset['pageNumber'];
};

type ProductsActions = {
  setProducts: (products: Product[]) => void;
  updateAssetPageNumber: (params: UpdatePageNumberParams) => void;
  removeAssetFromSide: (productId: ProductWithSides['id'], sideId: Side['id']) => void;
  removeSideFromProduct: (productId: ProductWithSides['id'], sideId: Side['id']) => void;
  setSelectedSideId: (sideId: Side['id']) => void;
  changeProductCount: (
    productId: ProductWithSides['id'],
    newCount: ProductWithSides['count'],
  ) => void;
  selectAssetForSide: (params: {
    assetId: Asset['id'];
    sideId: Side['id'];
    productId: string;
  }) => void;
  updateSide: (params: {
    productId: ProductWithSides['id'];
    sideId: Side['id'];
    actions: Side['actions'];
  }) => void;
  updateSidePreview: (params: {
    productId: ProductWithSides['id'];
    sideId: Side['id'];
    previewImageUrl: Side['previewImageUrl'];
    resizeMetadata: Side['resizeMetadata'];
  }) => void;
  deleteProduct: (productId: ProductWithSides['id']) => void;
};

const useProductsStore = create(
  subscribeWithSelector<ProductsState & ProductsActions>((set, get) => ({
    products: [],
    totalProductsCount: 0,
    setProducts: (products: ProductWithSides[]) => {
      set({ products });
    },
    updateAssetPageNumber: ({ pageNumber, productId, assetId }) =>
      set(({ products }) => {
        const updatedProducts = products.map((p) =>
          p.id === productId
            ? {
                ...p,
                sides: p.sides.map((s) =>
                  s.assetId === assetId ? { ...s, asset: { ...s, pageNumber } } : s,
                ),
              }
            : p,
        );
        return { products: updatedProducts };
      }),
    setSelectedSideId: (sideId: Side['id']) =>
      set(() => ({
        selectedSideId: sideId,
      })),
    changeProductCount: (productId: ProductWithSides['id'], newCount: ProductWithSides['count']) =>
      set(({ products }) => ({
        products: products.map((p) => (p.id === productId ? { ...p, count: newCount } : p)),
      })),
    removeAssetFromSide: (productId: ProductWithSides['id'], sideId: Side['id']) =>
      set(({ products }) => ({
        products: products.map((p) =>
          p.id === productId
            ? {
                ...p,
                sides: p.sides.map((s) => (s.id === sideId ? { ...s, assetId: null } : s)),
              }
            : p,
        ),
      })),
    removeSideFromProduct: (productId: ProductWithSides['id'], sideId: Side['id']) =>
      set(({ products }) => ({
        products: products.map((p) =>
          p.id === productId ? { ...p, sides: p.sides.filter((s) => s.id !== sideId) } : p,
        ),
      })),
    selectAssetForSide: ({ assetId, productId, sideId }) => {
      const asset = useAssetsStore.getState().getAsset(assetId);
      set(({ products }) => ({
        products: products.map((p) =>
          p.id === productId
            ? {
                ...p,
                sides: p.sides.map((s) => (s.id === sideId ? { ...s, assetId, asset } : s)),
              }
            : p,
        ),
      }));
    },
    deleteProduct: (productId) => {
      set(({ products }) => ({
        products: products.filter((p) => p.id !== productId),
      }));
    },
    updateSide: ({ productId, sideId, actions }) => {
      set(({ products }) => ({
        products: products.map((p) =>
          p.id === productId
            ? {
                ...p,
                sides: p.sides.map((s) => (s.id === sideId ? { ...s, actions } : s)),
              }
            : p,
        ),
      }));
    },
    updateSidePreview: ({ productId, sideId, previewImageUrl, resizeMetadata }) => {
      set(({ products }) => ({
        products: products.map((p) =>
          p.id === productId
            ? {
                ...p,
                sides: p.sides.map((s: any) =>
                  s.id === sideId ? { ...s, previewImageUrl, resizeMetadata } : s,
                ),
              }
            : p,
        ),
      }));
    },
  })),
);

// totalProductsCount changes to update shouldDisplayAddProduct
useProductsStore.subscribe(
  (state) => state.products,
  (products) => {
    const session = useSessionStore.getState().session;
    if (!session || isEmpty(session)) return;
    const { quantity, confirmation } = session;
    const totalProductsCount = getTotalProductsCount(products);
    useProductsStore.setState({ totalProductsCount });
    const shouldDisplayAddProduct = totalProductsCount < quantity;
    useLayoutStore.setState({ shouldDisplayAddProduct });
    // if session is confirmed, then products changed then setShouldHideConfirmation to false
    // to show the confirmation again
    if (confirmation.confirmed) {
      console.log('Products Updated');
      useLayoutStore.getState().setShouldHideConfirmation(false);
      API.confirmSession(session.id, { confirmed: false });
    }
  },
);

export default useProductsStore;
