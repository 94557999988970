import { useTranslation } from 'react-i18next';
import { RotateLeftOutlined, RotateRightOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Select, Tooltip } from 'antd';

import ToolItem from './ToolItem';
import { SContainer } from './Tools.styles';

const ToolsBar = ({
  place,
  type,
  imagePlaces,
  scalingMethods,
  onRotateLeft,
  onRotateRight,
  onFlip,
  onChangePlace,
  onChangeType,
}) => {
  const { t } = useTranslation();
  return (
    <SContainer>
      <ToolItem>
        <Select
          style={{ width: '100%' }}
          options={imagePlaces}
          value={place}
          onSelect={(value) => onChangePlace(value)}
        />
      </ToolItem>
      <ToolItem>
        <Select
          style={{ width: '100%' }}
          options={scalingMethods}
          value={type}
          onSelect={(value) => onChangeType(value)}
        />
      </ToolItem>
      <ToolItem>
        <Tooltip title={t('EditorActions.Rotate90Left')}>
          <Button
            icon={<RotateLeftOutlined />}
            onClick={onRotateLeft}
            style={{ marginRight: 10 }}
          />
        </Tooltip>
        <Tooltip title={t('EditorActions.Rotate90Right')}>
          <Button
            icon={<RotateRightOutlined />}
            onClick={onRotateRight}
            style={{ marginRight: 10 }}
          />
        </Tooltip>
        <Tooltip title={t('EditorActions.FlipHorizontal')}>
          <Button icon={<SwapOutlined />} onClick={onFlip} />
        </Tooltip>
      </ToolItem>
    </SContainer>
  );
};

export default ToolsBar;
