import { useEffect, useState } from 'react';

const useQueryParams = () => {
  const [sessionId, setSessionId] = useState('');
  const urlSearchParams = new URLSearchParams(window?.location?.search || '');
  const paramsFromUrl = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    if (paramsFromUrl.sessionId && paramsFromUrl.sessionId !== sessionId) {
      setSessionId(paramsFromUrl.sessionId);
    }
  }, [paramsFromUrl.sessionId, sessionId]);

  return {
    sessionId,
  };
};

export default useQueryParams;
