import axios from 'axios';

type ProgressStatus = 'done' | 'progress';

interface Progress {
  status: ProgressStatus;
  value: number;
}

const downloadFile = async (fileUrl: string, cb?: (progress: Progress) => void): Promise<Blob> => {
  const response = await axios.get(fileUrl, {
    responseType: 'blob',
    onDownloadProgress: (e) => {
      const value = 50 + Math.round((e.loaded * 100) / e.total / 2);
      cb &&
        cb({
          status: value === 100 ? 'done' : 'progress',
          value,
        });
    },
  });

  return response.data;
};

export default downloadFile;
