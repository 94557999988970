import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import useWebSocket from 'react-use-websocket';
import { Alert, Flex, Progress, Typography } from 'antd';

import useLayoutStore from 'hooks/stores/useLayoutStore';
import useSessionStore from 'hooks/stores/useSessionStore';

const WEB_SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL || 'ws://localhost:8080';

const RealTimeAssetsProcessing: FC = () => {
  const sessionId = useSessionStore((state) => state.sessionId);
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [progress, setProgress] = useState(0);
  const [assetName, setAssetName] = useState('');
  const [processedPages, setProcessedPages] = useState(0);
  const [visible, setVisible] = useLayoutStore((state) => [
    state.shouldDisplayAssetsProcessing,
    state.setShouldDisplayAssetsProcessing,
  ]);

  const { readyState, lastMessage } = useWebSocket(`${WEB_SOCKET_URL}/${sessionId}/assets/status`, {
    shouldReconnect: () => true,
    share: true,
  });

  const isVisible = (readyState === 1 || readyState === 2) && visible;

  const hasProcessedPages = processedPages > 0;

  useEffect(() => {
    if (lastMessage) {
      const { data } = lastMessage;
      const { percentage, status, error, assetName, numberOfProcessedPages } = JSON.parse(data) as {
        percentage: number;
        status: string;
        assetName: string;
        numberOfProcessedPages?: number;
        error?: string;
      };
      setProcessedPages(numberOfProcessedPages || 0);
      setAssetName(assetName);
      setVisible(true);
      if (status === 'error') setError(error || 'Fail to process pdf file');
      setProgress(percentage);
    }
  }, [assetName, lastMessage, setVisible]);

  useEffect(() => {
    const hasProcessed = progress === 100;
    if (!hasProcessed) return;
    const timeOut = setTimeout(() => {
      queryClient.invalidateQueries('assets');
      queryClient.invalidateQueries('products');
      setProgress(0);
      setVisible(false);
    }, 2500);
    return () => {
      clearTimeout(timeOut);
    };
  }, [progress, setVisible, queryClient]);

  // reset error after 5 seconds
  useEffect(() => {
    if (!error) return;
    const timeOut = setTimeout(() => {
      setError('');
      setVisible(false);
    }, 5000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [error, setVisible]);

  if (!isVisible || !lastMessage) return null;

  if (error) return <Alert message={error} type="error" showIcon closable />;

  return (
    <Flex vertical align="center" justify="center">
      <Progress percent={progress} strokeColor={{ from: '#108ee9', to: '#87d068' }} />
      <h5 style={{ textAlign: 'center', marginBottom: 0 }}>
        {t('RealTimeProcessing.Processing', { assetName })}
      </h5>
      {hasProcessedPages && (
        <h5 style={{ textAlign: 'center', marginBottom: 0 }}>
          {t('RealTimeProcessing.Instruction', { pages: processedPages })}
        </h5>
      )}
      <Typography.Text type="secondary" style={{ textAlign: 'center', marginBottom: '0.5rem' }}>
        {t('RealTimeProcessing.Instruction')}
      </Typography.Text>
    </Flex>
  );
};

export default RealTimeAssetsProcessing;
