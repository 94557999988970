import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import useLayoutStore from 'hooks/stores/useLayoutStore';
import useSessionStore from 'hooks/stores/useSessionStore';

import API from 'services/API';

export default function AddProduct() {
  const { t } = useTranslation();
  const shouldDisplayAddProduct = useLayoutStore((state) => state.shouldDisplayAddProduct);
  const sessionId = useSessionStore((state) => state.sessionId);

  const queryClient = useQueryClient();
  const useAddProductMutation = useMutation(() => API.addProduct(sessionId), {
    onSuccess: () => {
      queryClient.invalidateQueries('products');
    },
  });

  if (!shouldDisplayAddProduct) return null;

  return (
    <Button
      type="primary"
      size="middle"
      style={{ marginBottom: '1rem' }}
      onClick={() => useAddProductMutation.mutate()}
      icon={<PlusOutlined />}
    >
      {t('Add Product')}
    </Button>
  );
}
