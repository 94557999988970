import { useTranslation } from 'react-i18next';
import { FcHighPriority } from 'react-icons/fc';
import { IoAlertCircleOutline } from 'react-icons/io5';
import { CaretDownOutlined } from '@ant-design/icons';
import { Flex, Typography } from 'antd';

type Props = {
  lowestResolution: number;
  minimalDpi: number;
};

function DPISlider({ marginX = 50 }: { marginX: number }) {
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <div style={{ margin: `0 ${marginX - 5}%` }}>
        <CaretDownOutlined />
      </div>
      <div
        className="gradient-bar"
        style={{
          background: 'linear-gradient(to right, red, yellow, green)',
          height: '1rem',
        }}
      ></div>
    </div>
  );
}

function calculateMarginX(lowestResolution: number, minimalDpi: number) {
  // Calculate the percentage difference
  const percentageDifference = ((lowestResolution - minimalDpi) / minimalDpi) * 100;

  if (percentageDifference <= -20) {
    // More than 20% smaller, return 0% (left in the red area)
    return 0;
  } else if (percentageDifference >= 20) {
    // More than 20% larger, return 100% (right in the green area)
    return 100;
  } else if (percentageDifference < 0) {
    // Between 20% smaller and equal, interpolate between 0% and 65%
    return ((percentageDifference + 20) / 20) * 65;
  } else {
    // Between equal and 20% larger, interpolate between 65% and 100%
    return 65 + (percentageDifference / 20) * 35;
  }
}

export default function ResolutionMessage({ lowestResolution, minimalDpi }: Props) {
  const { t } = useTranslation();

  const noBitMapOrImage = lowestResolution === -1;

  const percentage = calculateMarginX(lowestResolution, minimalDpi);

  const isInBetweenLowAndNormal = percentage >= 0 && percentage < 20;
  const isInBetweenNormalAndHigh = percentage >= 20 && percentage < 60;

  if (noBitMapOrImage) {
    return <Typography.Text>{t('ResolutionNoBitmapOrImage')}</Typography.Text>;
  }

  if (isInBetweenLowAndNormal) {
    return (
      <Flex vertical justify="flex-start" align="center">
        <Flex justify="flex-start" align="center">
          <Typography.Text type="danger" style={{ fontWeight: 'bold' }}>
            {t('Resolution')}: {lowestResolution} DPI
          </Typography.Text>
          <FcHighPriority style={{ fontSize: '1rem', marginLeft: '0.5rem' }} />
        </Flex>
        <DPISlider marginX={percentage} />
      </Flex>
    );
  }

  if (isInBetweenNormalAndHigh) {
    return (
      <Flex vertical justify="flex-start" align="center">
        <Flex justify="flex-start" align="center">
          <Typography.Text type="warning" style={{ fontWeight: 'bold' }}>
            {t('Resolution')}: {lowestResolution} DPI
          </Typography.Text>
          <IoAlertCircleOutline
            style={{
              fontSize: '1.1rem',
              fontWeight: 'bold',
              marginLeft: '0.2rem',
              color: '#faad14',
            }}
          />
        </Flex>
        <DPISlider marginX={percentage} />
      </Flex>
    );
  }

  return (
    <Flex vertical justify="flex-start" align="center">
      <Flex justify="flex-start" align="center">
        <Typography.Text type="success" style={{ fontWeight: 'bold' }}>
          {t('Resolution')}: {lowestResolution} DPI
        </Typography.Text>
      </Flex>
      <DPISlider marginX={percentage} />
    </Flex>
  );
}
