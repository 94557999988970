import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Typography } from 'antd';

import Box from 'components/Box/Box';
import Counter from 'components/ProductCard/Counter';

import useProductsStore from 'hooks/stores/useProductsStore';
import useSessionStore from 'hooks/stores/useSessionStore';

import API from 'services/API';

type Props = {
  productId: string;
  productIndex: number;
};

const ProductHeader = ({ productId, productIndex }: Props) => {
  const { t } = useTranslation();
  const productsStore = useProductsStore((state) => state);
  const sessionId = useSessionStore((state) => state.sessionId);

  const queryClient = useQueryClient();
  const deleteProductMutation = useMutation(
    (productId: string) => API.deleteProduct(sessionId, productId),
    {
      onMutate: (productId) => {
        productsStore.deleteProduct(productId);
      },
      onSuccess: () => {
        queryClient.invalidateQueries('products');
      },
    },
  );

  const handleDeleteProduct = () => {
    deleteProductMutation.mutate(productId);
  };

  const count = productsStore.products[productIndex].count;
  const productNumber = productIndex + 1;
  const productName = `Product ${productNumber}`;

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={'1rem'}>
      <Typography.Title level={5}>{productName}</Typography.Title>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Counter count={count} productId={productId} />
        <Dropdown
          menu={{
            items: [
              {
                key: 'delete',
                onClick: () => handleDeleteProduct(),
                label: t('Delete'),
              },
            ],
          }}
          trigger={['click']}
        >
          <MoreOutlined
            style={{ fontSize: 24, cursor: 'pointer', marginLeft: '1rem' }}
            onClick={(e) => e.preventDefault()}
          />
        </Dropdown>
      </Box>
    </Box>
  );
};

export default ProductHeader;
