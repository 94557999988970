import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

type LayoutState = {
  shouldDisplayAddProduct: boolean;
  shouldHighlightDropArea: boolean;
  shouldDisplayAssetsProcessing: boolean;
  shouldHideConfirmation: boolean;
};

type LayoutActions = {
  setShouldHighlightDropArea: (shouldHighlightDropArea: boolean) => void;
  setShouldDisplayAddProduct: (shouldDisplayAddProduct: boolean) => void;
  setShouldDisplayAssetsProcessing: (shouldDisplayAssetsProcessing: boolean) => void;
  setShouldHideConfirmation: (shouldHideConfirmation: boolean) => void;
};

const useLayoutStore = create(
  subscribeWithSelector<LayoutState & LayoutActions>((set) => ({
    shouldDisplayAddProduct: true,
    shouldHighlightDropArea: false,
    shouldDisplayAssetsProcessing: false,
    shouldHideConfirmation: false,
    setShouldDisplayAddProduct: (shouldDisplayAddProduct: boolean) =>
      set({ shouldDisplayAddProduct }),
    setShouldHighlightDropArea: (shouldHighlightDropArea: boolean) =>
      set({ shouldHighlightDropArea }),
    setShouldDisplayAssetsProcessing: (shouldDisplayAssetsProcessing: boolean) =>
      set({ shouldDisplayAssetsProcessing }),
    setShouldHideConfirmation: (shouldHideConfirmation: boolean) => set({ shouldHideConfirmation }),
  })),
);

export default useLayoutStore;
