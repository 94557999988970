import { useCallback, useMemo, useRef } from 'react';

import useAssetsStore from 'hooks/stores/useAssetsStore';
import useProductsStore from 'hooks/stores/useProductsStore';
import useSessionStore from 'hooks/stores/useSessionStore';

export default function useAutomateUserWorkflow() {
  const { products, selectAssetForSide } = useProductsStore((state) => ({
    products: state.products,
    selectAssetForSide: state.selectAssetForSide,
  }));

  const { sidesCount, quantity } = useSessionStore((state) => {
    return {
      sidesCount: state.session?.sidesCount,
      quantity: state.session?.quantity,
    };
  });
  const assets = useAssetsStore((state) => state.assets);
  const hasAutomatedWorkFlowRef = useRef(false);

  const automateUserWorkflowInSingleSide = useCallback(() => {
    const side = products[0]?.sides[0];
    const asset = assets[0];
    if (side?.id && assets.length) {
      selectAssetForSide({
        assetId: asset.id,
        sideId: side.id,
        productId: products[0].id,
      });
      hasAutomatedWorkFlowRef.current = true;
    }
  }, [assets, products, selectAssetForSide]);

  const shouldSkipAutomateWorkflow: boolean = useMemo(() => {
    if (hasAutomatedWorkFlowRef.current) return true;
    const isSingleSideInSingleProduct = quantity === 1 && (sidesCount ?? 0) === 1;
    if (!isSingleSideInSingleProduct) return true;
    const isThereAssetIdInFirstSide = products[0]?.sides[0]?.assetId;
    if (!isThereAssetIdInFirstSide) return true;
    if (!assets.length) return true;
    return false;
  }, [assets.length, products, quantity, sidesCount]);

  return {
    shouldSkipAutomateWorkflow,
    automateUserWorkflowInSingleSide,
  };
}
