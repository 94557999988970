import { useCallback, useEffect, useRef, useState } from 'react';
import { MouseEvent } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { notification } from 'antd';
import { equals } from 'ramda';
import { Asset, ProductWithSides, SideWithAsset } from 'shared-types';
import { RotationDirection } from 'shared-types';
import styled from 'styled-components';

import Box from 'components/Box/Box';
import { Area } from 'components/EasyCrop/types';
import Editor from 'components/ProductSide/Editor';
import FilePreview from 'components/ProductSide/FilePreview';
import Toolbox from 'components/ProductSide/Toolbox';

import useSessionStore from 'hooks/stores/useSessionStore';
import useActions from 'hooks/useActions';
import useDownloadPDF from 'hooks/useDownloadPDF';
import useEditor from 'hooks/useEditor';

import API from 'services/API';

const SFilename = styled.h5`
  color: #333;
  font-size: 14px;
  font-weight: 800;
  margin: 0;
`;

interface Props {
  side: SideWithAsset;
}

const ProductSide = ({ side }: Props) => {
  const queryClient = useQueryClient();
  const sessionId = useSessionStore((state) => state.sessionId);
  const [isOpen, setIsOpen] = useState(false);
  const [capturedArea, setCapturedArea] = useState<Area | null>(null);

  // Gebruik ref voor tussenliggende rotatie state
  const isRotatingRef = useRef(false);

  const { actions, id: sideId, productId } = side;
  const asset = side?.asset as Asset;

  const { pdfFile: originalPdf, isDownloading } = useDownloadPDF({ fileUrl: asset.previewFile });
  const { newActions, setActions, handleFlip, changePlace, changeType } = useActions(actions);

  const { mutate: updateSideMutation } = useMutation(
    `updateSide-${sideId}`,
    (variables: { sideId: string; actions: SideWithAsset['actions'] }) =>
      API.updateSide(sessionId, variables.sideId, { actions: variables.actions }),
    {
      onMutate: async (newVariables) => {
        await queryClient.cancelQueries('products');

        const previousProducts = queryClient.getQueryData<ProductWithSides[]>('products');

        queryClient.setQueryData<ProductWithSides[]>('products', (old) => {
          if (!old) return [];

          return old.map((product) => {
            if (product.id === productId) {
              return {
                ...product,
                sides: product.sides.map((productSide) =>
                  productSide.id === sideId
                    ? { ...productSide, actions: newVariables.actions }
                    : productSide,
                ),
              };
            }
            return product;
          });
        });

        return { previousProducts };
      },
      onError: (error, variables, context) => {
        if (context?.previousProducts) {
          queryClient.setQueryData<ProductWithSides[]>('products', context.previousProducts);
        }
        notification.error({
          message: 'Error updating side',
          description: 'Changes have been reverted',
        });
      },
    },
  );

  const handleRotate = useCallback(
    (direction: RotationDirection) => {
      if (isRotatingRef.current) return;
      isRotatingRef.current = true;

      // Bereken nieuwe rotatie één keer, gebruik deze overal
      const newRotation = Math.floor(
        (((actions.rotation + (direction === 'right' ? 90 : -90)) % 360) + 360) % 360,
      );

      // Creëer het nieuwe actions object met exacte waarden
      const updatedActions = {
        ...actions,
        rotation: newRotation,
      };

      // Update beiden met exact hetzelfde object
      updateSideMutation({
        sideId,
        actions: updatedActions,
      });

      setActions(updatedActions);

      setTimeout(() => {
        isRotatingRef.current = false;
      }, 100);
    },
    [actions, sideId, updateSideMutation, setActions],
  );

  const generatedPdf = useEditor({
    pdfFile: originalPdf,
    newActions,
    dimensions: asset.dimensions,
    cropArea: side.cropArea,
    metaData: asset.metadata,
    asset,
  });

  const handlePreview = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsOpen(true);
  }, []);

  // Effect voor andere acties (niet rotatie)
  useEffect(() => {
    if (!equals(actions, newActions) && !isRotatingRef.current) {
      updateSideMutation({
        sideId,
        actions: newActions,
      });
      setCapturedArea(null);
    }
  }, [actions, newActions, sideId, updateSideMutation]);

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" width="100%">
        <FilePreview
          file={generatedPdf || originalPdf}
          loading={isDownloading}
          pageWidth={120}
          isFullPreview={false}
          onClick={handlePreview}
        />
      </Box>
      <Toolbox
        onRotate={handleRotate}
        onFlip={handleFlip}
        productId={productId}
        sideId={sideId}
        onPreview={() => setIsOpen(true)}
      />
      <Box mt="4px">
        <SFilename>{asset.filename}</SFilename>
      </Box>
      {originalPdf && (
        <Editor
          isOpen={isOpen}
          pdfFile={generatedPdf}
          originalPdfFile={originalPdf}
          actions={newActions}
          width={asset.dimensions.width}
          height={asset.dimensions.width}
          onClose={() => setIsOpen(false)}
          onChangePlace={changePlace}
          onChangeType={changeType}
          onFlip={handleFlip}
          onRotate={handleRotate}
        />
      )}
    </>
  );
};

export default ProductSide;
