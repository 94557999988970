import { Col } from 'antd';
import { Asset } from 'shared-types';

import AssetPreview from './AssetPreview';

const AssetsPreviews = ({ assets }: { assets: Asset[] | undefined }) => {
  if (!assets?.length) return null;
  return (
    <>
      {assets?.map((asset) => (
        <Col key={asset.id} className="gutter-row" span={12}>
          <AssetPreview assetId={asset.id} previewImage={asset.previewImage} />
        </Col>
      ))}
    </>
  );
};

export default AssetsPreviews;
