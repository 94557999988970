import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Box from 'components/Box/Box';

import useSessionStore from 'hooks/stores/useSessionStore';

const Instructions: FC = () => {
  const { t } = useTranslation();
  const session = useSessionStore((state) => state.session);
  const instructions = t('Instructions', {
    returnObjects: true,
  }) as string[];

  const instructionsForOneProduct = instructions.slice(2, 5);

  return (
    <Box color="#6b7280" textAlign="left" style={{ marginTop: '1rem' }}>
      {/* Drag and drop assets to the product */}
      <h4>{t('Explanation')}</h4>
      <ul>
        {session?.quantity ?? 0 > 1
          ? instructions.map((instruction, index) => (
              <li
                key={index}
                style={{
                  marginBottom: '0.5rem',
                }}
              >
                {instruction}
              </li>
            ))
          : instructionsForOneProduct.map((instruction, index) => (
              <li
                key={index}
                style={{
                  marginBottom: '0.5rem',
                }}
              >
                {instruction}
              </li>
            ))}
      </ul>
    </Box>
  );
};

export default Instructions;
