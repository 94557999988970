import { useEffect, useMemo, useState } from 'react';
import { Metadata } from 'pdfjs-dist/types/src/display/metadata';
//import { Metadata } from 'pdfjs-dist/types/src/display/metadata';
import { Asset, SideWithAsset } from 'shared-types';
import { height } from 'styled-system';

import useSessionStore from 'hooks/stores/useSessionStore';

import { createCropArea, createCropPDFv2, drawBleed, getSizeInPixel } from 'utils/pdf';

import { calculatePlacement, PDFPlacement } from '../utils/pdfv2';

type IUseEditor = {
  pdfFile: Blob | undefined;
  newActions: SideWithAsset['actions'];
  metaData?: Asset['metadata'];
  dimensions: Asset['dimensions'];
  cropArea: SideWithAsset['cropArea'];
  asset: Asset;
};

const defaultActions = { rotation: 0, flip: false, type: 'CROP', place: 'GROSS' };

const useEditor = ({ pdfFile, newActions, dimensions, cropArea, metaData, asset }: IUseEditor) => {
  //const [generatedPdf, setGeneratedPdf] = useState<Blob>(pdfFile as Blob);
  const [generatedPdf, setGeneratedPdf] = useState<Blob | undefined>(pdfFile);
  const session = useSessionStore((state) => state.session);
  const productSize = session?.productSize;
  const bleed = session?.bleed;
  useEffect(() => {
    console.log('useEditor input values:', {
      hasFile: !!pdfFile,
      actions: newActions,
      metadata: metaData,
      dimensions,
      cropArea,
      rotation: newActions.rotation,
      asset,
    });
  }, [pdfFile, newActions, metaData, dimensions, cropArea]);

  // when crop area is set use the default actions to generate the pdf
  const actions = cropArea ? defaultActions : newActions;

  const generatedFile = useMemo(async () => {
    if (!pdfFile) return;
    try {
      console.log('Crooooop Area', cropArea);
      console.log({
        productSize,
        dimensions,
        bleed,
        ...actions,
        cropArea,
        assetMetaData: metaData,
      });

      const pTrim = {
        x: bleed?.left,
        y: bleed?.bottom,
        width: productSize?.width,
        height: productSize?.height,
      };
      if (!cropArea) {
        cropArea = createCropArea(asset?.dimensions?.trimbox, pTrim);
      }

      let placement = calculatePlacement({
        assetDimensions: {
          width: asset?.dimensions.width,
          height: asset?.dimensions.height,
          trimbox: asset?.dimensions.trimbox,
          // width: metaData?.page.width || 0,
          // height: metaData?.page.height || 0,
          // trimbox: metaData?.trimbox || { x: 0, y: 0, width: 0, height: 0 },
        },
        productDimensions: {
          width: getSizeInPixel(Number(productSize?.width)) || 0,
          height: getSizeInPixel(Number(productSize?.height)) || 0,
          bleed: {
            left: getSizeInPixel(bleed?.left) || 0,
            top: getSizeInPixel(bleed?.top) || 0,
            right: getSizeInPixel(bleed?.right) || 0,
            bottom: getSizeInPixel(bleed?.bottom) || 0,
          },
        },
        cropArea: {
          x: Number(cropArea?.x || 0),
          y: Number(cropArea?.y || 0),
          width: Number(cropArea?.width || 100),
          height: Number(cropArea?.height || 100),
        },
      });

      const pBleed = {
        left: getSizeInPixel(Number(bleed?.left)) || 0,
        top: getSizeInPixel(Number(bleed?.top)) || 0,
        right: getSizeInPixel(Number(bleed?.right)) || 0,
        bottom: getSizeInPixel(Number(bleed?.bottom)) || 0,
      };

      console.log('PLACEMENT', placement);
      const pw = getSizeInPixel(Number(productSize?.width)) || 0;
      const ph = getSizeInPixel(Number(productSize?.height)) || 0;
      const bl = pBleed?.left;
      const bt = pBleed?.top;
      const br = pBleed?.right;
      const bb = pBleed?.bottom;

      const _product: PDFPlacement = {
        x: 0,
        y: 0,
        width: pw + bl + br,
        height: ph + bt + bb,
        shiftX: 0,
        shiftY: 0,
        scale: 1, //placement.scale
      };

      const _bleed: PDFPlacement = {
        x: bl,
        y: bt,
        width: pw,
        height: ph,
        shiftX: 0,
        shiftY: 0,
        scale: 1,
      };
      console.log('BEFORE createTestPDF', { placement, _product, bl, bb });

      let file = await createCropPDFv2(pdfFile, placement, _product, _bleed);
      file = await drawBleed(file, { width: pw + bl + br, height: ph + bt + bb, bleed: pBleed });

      console.log('returned file', file);
      return new Blob([file]);
    } catch (e) {
      console.error('Error while generating the file', e);
    }
  }, [pdfFile, actions, cropArea, bleed, dimensions, metaData, productSize]);

  useEffect(() => {
    generatedFile.then((file) => {
      if (file) {
        console.log('setGeneratedPdf', file);
        setGeneratedPdf(file);
      }
    });
  }, [generatedFile]);

  return generatedPdf;
};

export default useEditor;
