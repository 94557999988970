import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Flex, Modal, Progress, Upload } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload/interface';
import axios from 'axios';

import Box from 'components/Box/Box';

import useLayoutStore from 'hooks/stores/useLayoutStore';
import useSessionStore from 'hooks/stores/useSessionStore';

import API from 'services/API';

import { colors } from 'utils/constants';

type Props = {
  showAddBlankPage: boolean;
};

const AssetsAndSidesActions: React.FC<Props> = ({ showAddBlankPage }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const sessionId = useSessionStore((state) => state.sessionId);
  const setVisible = useLayoutStore((state) => state.setShouldDisplayAssetsProcessing);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { mutate: deleteAllSidesMutation } = useMutation(
    'deleteSide',
    () => API.deleteAllSides(sessionId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('products');
      },
    },
  );
  const onChange: UploadProps<File>['onChange'] = async ({ file: { originFileObj } }) => {
    try {
      setIsUploading(true);
      const file = originFileObj as RcFile;
      const signedUrl = await API.getSignedUrl(sessionId, file);
      if (!signedUrl) throw new Error('Failed to get signed url');

      const response = await axios.put(signedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (progressEvent) => {
          const progressPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progressPercent);
        },
      });
      if (response.status !== 200) throw new Error('Upload failed');
      await API.postNewUploadMessage(sessionId, file);
      setVisible(true);
    } catch (error) {
      setError('Upload failed');
    } finally {
      setIsUploading(false);
    }
  };

  const handleAddBlankPage = async () => {
    try {
      setIsLoading(true);
      const response = await API.postCreateBlankPage(sessionId);
      if (response.status !== 202) throw new Error('Generate blank page failed');
      setVisible(true);
    } catch (error) {
      setError('Upload failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box mt={2}>
      {error && <Alert message={error} type="error" />}

      {!isUploading && (
        <Upload.Dragger onChange={onChange} multiple={true} accept=".pdf,.jpg,.jpeg,.png">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t('Drag file or click to upload')}</p>
        </Upload.Dragger>
      )}

      {isUploading && (
        <>
          <Progress percent={uploadProgress} status="active" />
          <h5 style={{ textAlign: 'center' }}>{t('Uploading')}</h5>
        </>
      )}

      {showAddBlankPage && (
        <>
          <Divider />
          <Modal
            title="Confirm Delete"
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={null}
          >
            <p>{t('DeletePageConfirmation')}</p>
            <Flex justify="flex-end">
              <Button onClick={() => setIsModalOpen(false)}>{t('Cancel')}</Button>
              <Button
                style={{ marginLeft: '1rem' }}
                onClick={() => {
                  setIsModalOpen(false);
                  deleteAllSidesMutation();
                }}
                danger
              >
                {t('Delete')}
              </Button>
            </Flex>
          </Modal>
          <Flex justify="space-evenly" align="center">
            <Button
              type="default"
              block
              onClick={handleAddBlankPage}
              loading={isLoading}
              icon={<PlusOutlined />}
              size="small"
              style={{
                backgroundColor: colors.darkGreen,
                color: colors.lightGreen,
                width: 'auto',
              }}
            >
              {t('Add Blank Page')}
            </Button>
            <Button type="primary" danger onClick={() => setIsModalOpen(true)} size="small">
              {t('DeleteAll')}
            </Button>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default AssetsAndSidesActions;
