import { useTranslation } from 'react-i18next';
import { RiDragDropLine } from 'react-icons/ri';
import { useMutation } from 'react-query';
import { Asset, Side } from 'shared-types';

import Box from 'components/Box/Box';

import useLayoutStore from 'hooks/stores/useLayoutStore';
import useProductsStore from 'hooks/stores/useProductsStore';
import useSessionStore from 'hooks/stores/useSessionStore';

import API from 'services/API';

const overlayStyle = {
  background: 'rgba(0, 0, 0, 0.3)',
};

type Props = {
  productId: string;
  sideId: Side['id'];
};

type Params = Props;

const EmptyProductSide = ({ productId, sideId }: Props) => {
  const { t } = useTranslation();
  const layoutStore = useLayoutStore((state) => state);
  const selectAssetForSide = useProductsStore((state) => state.selectAssetForSide);
  const sessionId = useSessionStore((state) => state.sessionId);

  const useAddAssetToSideMutation = useMutation(
    'updateSide',
    (variables: { sideId: Side['id']; assetId: Asset['id'] }) =>
      API.addAssetToSide(sessionId, variables.assetId, variables.sideId),
    {
      onError: () => {
        // queryClient.invalidateQueries('products');
      },
      onSuccess: () => {
        // queryClient.invalidateQueries('products');
      },
    },
  );

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    layoutStore.setShouldHighlightDropArea(false);
  };

  const handleDrop = async function (
    event: React.DragEvent<HTMLDivElement>,
    { productId, sideId }: Params,
  ) {
    event.preventDefault();
    const assetId = event.dataTransfer.getData('assetId');
    selectAssetForSide({ productId, sideId, assetId });
    useAddAssetToSideMutation.mutate({ sideId, assetId });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      onDragOver={(e) => handleDragOver(e)}
      onDrop={(e) => handleDrop(e, { productId, sideId })}
      style={layoutStore.shouldHighlightDropArea ? overlayStyle : undefined}
    >
      <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
        <RiDragDropLine size={50} />
        <Box margin="0.5rem 0" textAlign={'center'}>
          {t('Drag and drop an image here')}
        </Box>
      </Box>
    </Box>
  );
};

export default EmptyProductSide;
