import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Button, Card, Col, Result, Row, Spin } from 'antd';

import AddProduct from 'components/AddProduct/AddProduct';
import AssetsSidebar from 'components/AssetsSidebar/AssetsSidebar';
import Box from 'components/Box/Box';
import Products from 'components/Products/Products';

import useProductsStore from 'hooks/stores/useProductsStore';
import useSessionStore from 'hooks/stores/useSessionStore';
import useConfirmProducts from 'hooks/useConfirmProducts';
import useQueryParams from 'hooks/useQueryParams';

import API from 'services/API';

import { SContainer, SLoaderContainer, SSticky } from './Uploader.styles';

const Uploader = () => {
  const { sessionId } = useQueryParams();
  const { t } = useTranslation();
  const { totalProductsCount, products } = useProductsStore((state) => ({
    totalProductsCount: state.totalProductsCount,
    products: state.products,
  }));
  const { session, setSessionId, setSession } = useSessionStore((state) => {
    return {
      session: state.session,
      setSessionId: state.setSessionId,
      setSession: state.setSession,
    };
  });

  const {
    isLoading,
    error: ConfigError,
    isError: isConfigError,
  } = useQuery('session', () => API.getSession(sessionId), {
    enabled: !!sessionId,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setSessionId(sessionId);
      setSession(data);
    },
  });

  const { isConfirming, handleConfirm } = useConfirmProducts({
    products,
    totalProductsCount,
    session,
    sessionId,
  });

  if (isLoading || !session) {
    return (
      <SLoaderContainer>
        <Spin size="large" />
      </SLoaderContainer>
    );
  }

  if (isConfigError || ConfigError) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={<Button type="primary">Send an Email</Button>}
      />
    );
  }

  return (
    <SContainer>
      <Box>
        <SSticky>
          <Box display="flex" alignContent={'center'} justifyContent={'space-between'}>
            <Box>
              <h3>
                {t('Allocated Products')} {totalProductsCount} / {session?.quantity}
              </h3>
            </Box>
            <Box>
              <Button
                type="primary"
                size="middle"
                onClick={handleConfirm}
                loading={isConfirming}
                style={{ background: '#eab308', borderColor: '#eab308' }}
              >
                {t('Check Files')}
              </Button>
            </Box>
          </Box>
        </SSticky>
        <Row gutter={[16, 16]}>
          <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
            <AssetsSidebar />
          </Col>
          <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 18 }}>
            <Card style={{ overflow: 'auto', maxHeight: '100vh' }}>
              <AddProduct />
              <Row className="gutter-row" gutter={[16, 16]}>
                <Products />
              </Row>
            </Card>
          </Col>
        </Row>
      </Box>
    </SContainer>
  );
};
export default Uploader;
