import { useMemo } from 'react';
import { Card, Col, Row } from 'antd';
import { isNil } from 'ramda';
import { ProductWithSides, SideWithAsset } from 'shared-types';
import styled from 'styled-components';

import Box from 'components/Box/Box';
import EmptyProductSide from 'components/ProductSide/EmptyProductSide';
import ProductSide from 'components/ProductSide/ProductSide';

import useSessionStore from 'hooks/stores/useSessionStore';

import ProductHeader from './ProductHeader';

export const SCard = styled(Card)`
  width: 100%;
  border-radius: 8px;
`;

export const SCardContent = styled(Box)`
  height: 265px;
  padding: 10px;
  justify-content: center;
  cursor: pointer;
`;

export const SDelete = styled(Box)`
  display: flex;
  justify-content: center;
`;

const getColsBySideCountsForLgScreen = (sidesCount: number) => {
  switch (sidesCount) {
    case 1:
      return 24;
    case 2:
      return 12;
    case 3:
      return 8;
    case 4:
      return 6;
    default:
      return 8;
  }
};

type Props = {
  product: ProductWithSides;
  productIndex: number;
};

const ProductCard = ({ product, productIndex }: Props) => {
  const session = useSessionStore((state) => state.session);

  const sides = useMemo(() => {
    return product.sides || [];
  }, [product.sides]);

  if (!session) return null;

  return (
    <SCard>
      <ProductHeader productId={product.id} productIndex={productIndex} />
      <Row gutter={[16, 16]}>
        {(sides || []).map((side, index) => (
          <Col
            className="gutter-row"
            lg={{ span: getColsBySideCountsForLgScreen(session.sidesCount) }}
            key={index}
          >
            <Card key={side.id} bodyStyle={{ padding: 0 }}>
              <SCardContent>
                {isNil(side?.assetId) ? (
                  <EmptyProductSide productId={product.id} sideId={side.id} />
                ) : (
                  <ProductSide side={side as SideWithAsset} />
                )}
              </SCardContent>
            </Card>
          </Col>
        ))}
      </Row>
    </SCard>
  );
};

export default ProductCard;
