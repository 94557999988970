import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// import translation files
import en from './locales/en.json';
import nl from './locales/nl.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init(
    {
      resources: {
        en: {
          translation: en,
        },
        nl: {
          translation: nl,
        },
      },
      fallbackLng: 'en', // fallback language
      interpolation: {
        escapeValue: false,
      },
      detection: {
        order: ['querystring', 'navigator'],
        lookupQuerystring: 'lng',
      },
    },
    (err, t) => {
      if (err) {
        console.error('i18n error', err);
      }
    },
  );

export default i18n;
