import { useState } from 'react';
import { FC } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { Asset } from 'shared-types';
import styled from 'styled-components';

import useLayoutStore from 'hooks/stores/useLayoutStore';

interface Props {
  previewImage: string;
  assetId: Asset['id'];
  style?: React.CSSProperties;
}

export const SAssetPreview = styled.div`
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2px;
  margin-bottom: 8px;
  height: 130px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
  }
`;

const AssetPreview: FC<Props> = ({ previewImage, assetId, style }) => {
  const layoutStore = useLayoutStore((state) => state);

  const onDragStart = (event: React.DragEvent<HTMLDivElement>, assetId: Asset['id']) => {
    event.dataTransfer.setData('assetId', assetId);
    layoutStore.setShouldHighlightDropArea(true);
  };

  return (
    <SAssetPreview onDragStart={(e) => onDragStart(e, assetId)} style={style}>
      {previewImage ? (
        <img
          src={previewImage}
          alt="Asset preview"
          loading="lazy" // Voegt lazy loading toe voor betere performance
        />
      ) : (
        <ClipLoader color="#35b3ee" /> // Toon een loader als er nog geen preview beschikbaar is
      )}
    </SAssetPreview>
  );
};

export default AssetPreview;
