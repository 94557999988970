import { useCallback, useEffect, useState } from 'react';

import downloadFile from 'services/downloadService';

type IUseDownloadPDF = {
  fileUrl: string;
};

const useDownloadPDF = ({ fileUrl }: IUseDownloadPDF) => {
  const [pdfFile, setPdfFile] = useState<Blob | undefined>(undefined);
  const [isDownloading, setIsDownloading] = useState(false);
  const [progress, setProgress] = useState({ status: 'progress', value: 0 });

  const download = useCallback(async () => {
    try {
      setIsDownloading(true);
      const file = await downloadFile(fileUrl, setProgress);
      if (!file) return;
      setPdfFile(file);
    } catch (error) {
      // handle error
    } finally {
      setIsDownloading(false);
    }
  }, [fileUrl]);

  // download pdf from url and set it to pdfFile
  useEffect(() => {
    download();
  }, [download]);

  return { pdfFile, isDownloading, progress };
};

export default useDownloadPDF;
