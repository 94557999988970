import { useMutation, useQueryClient } from 'react-query';
import MinusOutlined from '@ant-design/icons/MinusOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import styled from 'styled-components';

import useProductsStore from 'hooks/stores/useProductsStore';
import useSessionStore from 'hooks/stores/useSessionStore';

import API from 'services/API';

export const SContainer = styled.main`
  padding: 0.1rem;
`;

const SContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SCount = styled.input`
  width: 35px;
  height: 35px;
  padding: 0.2rem;
  font-size: 20px;
  font-weight: 700;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  margin: 0 0.4rem;
  text-align: center;
`;

const styles = {
  icons: { fontSize: 15, cursor: 'pointer' },
};

type Props = {
  count: number;
  productId: string;
};

const Counter = ({ count, productId }: Props) => {
  const sessionId = useSessionStore((state) => state.sessionId);
  const queryClient = useQueryClient();
  const changeProductCount = useProductsStore((state) => state.changeProductCount);

  const useUpdateProductMutation = useMutation(
    'updateProduct',
    (variables: { productId: string; count: number }) =>
      API.updateProductCount(sessionId, variables.productId, variables.count),
    {
      onMutate: async ({ productId, count }) => {
        await queryClient.cancelQueries('products');
        changeProductCount(productId, count);
      },
      onError: () => {
        // queryClient.invalidateQueries('products');
      },
      onSuccess: () => {
        // queryClient.invalidateQueries('products');
      },
    },
  );

  const handleCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const parsedValue = parseInt(value);
    if (isNaN(parsedValue)) return;
    useUpdateProductMutation.mutate({ productId, count: parsedValue });
  };

  const handleIncrement = () => {
    useUpdateProductMutation.mutate({ productId, count: count + 1 });
  };

  const handleDecrement = () => {
    if (count === 0) return;
    useUpdateProductMutation.mutate({ productId, count: count - 1 });
  };

  return (
    <SContainer>
      <SContent>
        <MinusOutlined style={styles.icons} onClick={handleDecrement} />
        <SCount onChange={handleCountChange} type="number" value={count} min={0} />
        <PlusOutlined style={styles.icons} onClick={handleIncrement} />
      </SContent>
    </SContainer>
  );
};

export default Counter;
