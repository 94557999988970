import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDeleteForever, MdDragIndicator } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Card, Flex, Modal, Typography } from 'antd';
import { SideWithAsset } from 'shared-types';

import AssetPreview from 'components/AssetsSidebar/AssetPreview';

import useProductsStore from 'hooks/stores/useProductsStore';
import useSessionStore from 'hooks/stores/useSessionStore';

import API from 'services/API';

import { colors } from 'utils/constants';

const { Text } = Typography;

const SortablePageItem = ({
  side,
  index,
  previewFile,
}: {
  side: SideWithAsset;
  index: number;
  previewFile: string;
}) => {
  const sessionId = useSessionStore((state) => state.sessionId);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { setSelectedSide, removeSideFromProduct, selectedSideId } = useProductsStore((state) => {
    return {
      setSelectedSide: state.setSelectedSideId,
      removeSideFromProduct: state.removeSideFromProduct,
      selectedSideId: state.selectedSideId,
    };
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate: deleteSideMutation } = useMutation(
    'deleteSide',
    () => API.deleteSide(sessionId, side.id),
    {
      onMutate: async () => {
        // cancel any update side mutation
        await queryClient.cancelQueries(`updateSide-${side.id}`);
      },
    },
  );

  const handleDeleteSide = () => {
    removeSideFromProduct(side.productId, side.id);
    deleteSideMutation();
  };

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: side.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: '100%',
    marginBottom: '0.4rem',
    backgroundColor: selectedSideId === side.id ? '#DBEAFE' : '#FAFAF9',
    height: 'auto',
  };

  const pageNumber = index + 1;

  if (!side.asset) return null;

  return (
    <Card onClick={() => setSelectedSide(side.id)} style={style}>
      <Flex key={side.id} justify="flex-start" align="center">
        <AssetPreview previewImage={previewFile} assetId={side.assetId} />
        <Flex justify="space-between" align="center" style={{ width: '100%' }}>
          <Text
            style={{ marginLeft: '1rem', fontSize: '1.1rem', fontWeight: 500, color: '#616161' }}
          >{`${t('Page')} ${pageNumber}`}</Text>
          <Flex style={{ marginLeft: 'auto' }} align="center">
            <MdDeleteForever
              size={25}
              style={{ marginRight: '.5rem', color: '#B91C1C' }}
              onClick={() => setIsModalOpen(true)}
            />
            <button
              style={{
                border: 'none',
                backgroundColor: colors.lightGray, // '#E4E4E7',
                padding: '0px',
                borderRadius: '6px',
                textAlign: 'center',
                boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
                cursor: 'grab',
              }}
              ref={setNodeRef}
              {...attributes}
              {...listeners}
            >
              <MdDragIndicator spacing={25} size={25} style={{ color: colors.darkGray }} />
            </button>
          </Flex>
        </Flex>
      </Flex>
      <Modal
        title="Confirm Delete"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <p>{t('DeletePageConfirmation')}</p>
        <Flex justify="flex-end">
          <Button onClick={() => setIsModalOpen(false)}>{t('Cancel')}</Button>
          <Button
            style={{ marginLeft: '1rem' }}
            onClick={() => {
              setIsModalOpen(false);
              handleDeleteSide();
            }}
            danger
          >
            {t('Delete')}
          </Button>
        </Flex>
      </Modal>
    </Card>
  );
};

export default SortablePageItem;
