export const IMAGE_PlACE = Object.freeze({
  GROSS: 'GROSS',
  NET: 'NET',
  NEGATIVE_WHITE_SPACE: 'NEGATIVE_WHITE_SPACE',
});

export const SCALING_METHOD = Object.freeze({
  FIT: 'FIT',
  CROP: 'CROP',
  STRETCH: 'STRETCH',
});

export const CHUNK_SIZE_IN_MB = 20 * 1024 * 1024;

export const PDF_PREVIEW_WIDTH = 100;

export const MAX_PRODUCTS_NUMBER = 10;

export const PREVIEW_IMAGE_SIZE = 120 * 1204;

export const DEFAULT_SIDES_COUNT = 2;

export const imagePlaces = [{ label: 'Afbeelding bruto', value: IMAGE_PlACE.GROSS }];

export const scalingMethods = [
  { label: 'Passend plaatsen', value: SCALING_METHOD.FIT },
  { label: 'Bijsnijden', value: SCALING_METHOD.CROP },
  { label: 'Uitrekken', value: SCALING_METHOD.STRETCH },
];

export const defaultGeneratePdfValues = {
  type: SCALING_METHOD.CROP,
  place: IMAGE_PlACE.GROSS,
};

// Tailwind CSS colors
const TailwindColors = {
  white: '#ffffff',
  black: '#000000',
  gray: {
    '100': '#f7fafc',
    '200': '#edf2f7',
    '300': '#e2e8f0',
    '400': '#cbd5e0',
    '500': '#a0aec0',
    '600': '#718096',
    '700': '#4a5568',
    '800': '#2d3748',
    '900': '#1a202c',
  },
  red: {
    '100': '#fff5f5',
    '200': '#fed7d7',
    '300': '#feb2b2',
    '400': '#fc8181',
    '500': '#f56565',
    '600': '#e53e3e',
    '700': '#c53030',
    '800': '#9b2c2c',
    '900': '#742a2a',
  },
  orange: {
    '100': '#fffaf0',
    '200': '#feebc8',
    '300': '#fbd38d',
    '400': '#f6ad55',
    '500': '#ed8936',
    '600': '#dd6b20',
    '700': '#c05621',
    '800': '#9c4221',
    '900': '#7b341e',
  },
  yellow: {
    '100': '#fffff0',
    '200': '#fefcbf',
    '300': '#faf089',
    '400': '#f6e05e',
    '500': '#ecc94b',
    '600': '#d69e2e',
    '700': '#b7791f',
    '800': '#975a16',
    '900': '#744210',
  },
  green: {
    '100': '#f0fff4',
    '200': '#c6f6d5',
    '300': '#9ae6b4',
    '400': '#68d391',
    '500': '#48bb78',
    '600': '#38a169',
    '700': '#2f855a',
    '800': '#276749',
    '900': '#22543d',
  },
};

export const colors = {
  darkGray: TailwindColors.gray['800'],
  gray: TailwindColors.gray['700'],
  lightGray: TailwindColors.gray['200'],
  semiGray: TailwindColors.gray['300'],
  white: TailwindColors.white,
  black: TailwindColors.black,
  red: TailwindColors.red['500'],
  orange: TailwindColors.orange['500'],
  yellow: TailwindColors.yellow['500'],
  green: TailwindColors.green['500'],
  darkGreen: TailwindColors.green['700'],
  semiGreen: TailwindColors.green['300'],
  lightGreen: TailwindColors.green['200'],
};
