export interface PDFPlacement {
  x: number;
  y: number;
  width: number;
  height: number;
  shiftX: number;
  shiftY: number;
  scale: number;
}

export function calculatePlacement(input: {
  assetDimensions: {
    width: number;
    height: number;
    trimbox: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
  };
  productDimensions: {
    width: number;
    height: number;
    bleed: {
      left: number;
      top: number;
      right: number;
      bottom: number;
    };
  };
  cropArea: {
    x: number; // percentage
    y: number; // percentage
    width: number; // percentage
    height: number; // percentage
  };
}): {
  x: number;
  y: number;
  shiftX: number;
  shiftY: number;
  scale: number;
} {
  console.log('calculatePlacement', input);
  const assetTrimbox = input.assetDimensions.trimbox;

  if (!assetTrimbox) throw new Error('Trimbox is missing');
  if (assetTrimbox.width <= 0 || assetTrimbox.height <= 0)
    throw new Error('Trimbox has invalid size');

  const scaleX =
    (100 / input.cropArea.width) * (input.productDimensions.width / assetTrimbox.width);
  const scaleY =
    (100 / input.cropArea.height) * (input.productDimensions.height / assetTrimbox.height);
  // take largest as scale
  const scale = Math.max(scaleX, scaleY);

  // calculate asset x and y in pixels
  const cropPixels = {
    x: (input.cropArea.x * assetTrimbox.width) / 100,
    y: (input.cropArea.y * assetTrimbox.height) / 100,
  };

  console.log('placement calculations', { scaleX, scaleY, scale, cropPixels });

  // correct for product and asset bleed (this doesn't impact zoom, but shifts X and Y possibly)
  return {
    x: 0 - cropPixels.x,
    y: 0 - cropPixels.y,
    shiftX: assetTrimbox.x * scale - input.productDimensions.bleed.left,
    shiftY: assetTrimbox.y * scale - input.productDimensions.bleed.bottom, // - input.productDimensions.bleed.top,
    scale: scale,
  };
}
