import { pdfjs } from 'react-pdf';
import { QueryClient, QueryClientProvider } from 'react-query';

import Book from 'views/Book';
import MinimalBook from 'views/MinimalBook';
import Uploader from 'views/Uploader';

import GlobalStyle from 'styles/global';

import 'styles/custom.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const queryClient = new QueryClient();

function App() {
  const layoutQuery = new URLSearchParams(window.location.search);
  const isBookLayout = layoutQuery.get('layout') === 'book';
  const inMinimalBookLayout = layoutQuery.get('layout') === 'minimal-book';

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <GlobalStyle />
        {isBookLayout ? <Book /> : inMinimalBookLayout ? <MinimalBook /> : <Uploader />}
      </QueryClientProvider>
    </>
  );
}

export default App;
